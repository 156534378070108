import * as React from "react";
import { useState } from "react";

import Loader from "../components/walletconnect/components/Loader";
import { SContainer, STable, SRow, SKey, SValue } from "../components/walletconnect/components/sharedComponents";

import { SModalContainer, SModalTitle, SModalParagraph } from "../components/walletconnect/components/shared";
import { Button, Modal } from "react-bootstrap";

interface RequestModalProps {
  pending: boolean;
  result: any;
  setSignAddress: any;
  setPublicKey: any;
  setSignature: any;
  setUserWalletAddress: any;
  setMasterPublicKey: any;
  setStepNumber: any;
  name: any;
  modal: any;
  closeModal: any;
  setAddedVCProofs: any;
  setWalletConnected: any;
  setParentCoinInfo: any;
}

const RequestModal = (props: RequestModalProps) => {
  const {pending, result, setSignAddress, setPublicKey, setSignature, setUserWalletAddress, setMasterPublicKey, setParentCoinInfo, setStepNumber, name, modal, closeModal, setAddedVCProofs, setWalletConnected } = props;
  //{result ? console.log('result', JSON.parse(result?.result)) : console.log('')};
                if(result?.method === 'chia_getNextAddress') 
                { 
                    console.log('result_address', result);
                    const resultAddress = JSON.parse(result?.result);
                    console.log('result_address', resultAddress); 
                    setSignAddress(resultAddress?.data);
                    setUserWalletAddress(resultAddress?.data);
                    setStepNumber('2');
                } 
                else if(result?.method === 'chia_getPublicKey') {
                  console.log('pub_key_response', result);
                  const resultKey = JSON.parse(result?.result);
                  console.log(resultKey?.data?.key?.publicKey, 'PUB_KEY_RESULT');
                  setMasterPublicKey(resultKey?.data?.key?.publicKey);
                  setStepNumber('3');
                }
                else if(result?.method === 'chia_signMessageByAddress')
                {
                  console.log('result_sign_address', result);
                  const resultAddress = JSON.parse(result?.result);
                  console.log('result_address', resultAddress); 
                  setSignature(resultAddress?.data?.signature);
                  setPublicKey(resultAddress?.data?.pubkey);
                  setStepNumber('4');
                }
                else if(result?.method === 'chia_sendTransaction')
                {
                  console.log(result, 'chiaSendTransaction');
                  const response = JSON.parse(result?.result);
                  console.log(response.data.transaction.additions, 'additions_array');
                  setParentCoinInfo(response.data.transaction.additions[0].parentCoinInfo);
                }
                else if(result?.method === 'chia_addVCProofs')
                {
                  console.log(result, 'VC Proofs');
                  const response = JSON.parse(result?.result);
                  console.log(response.data, 'proofs');
                  if(response.data.success === true) {
                  setAddedVCProofs(true);
                  setWalletConnected(true);
                  }
                }
                else 
                { 
                    console.log('')
                };

  return (
    <>
      {pending ? (
        <Modal show={!!modal} onHide={closeModal} >
        <SModalContainer>
          {/* <SModalTitle>{"Pending JSON-RPC Request"}</SModalTitle> */}
          <SContainer>
            {/* <Loader /> */}
            <img src={require('./../assets/images/request-modal.png')} alt="Connect icon" className="me-1" style={{"marginBottom":"20px", "marginTop": "20px"}}/>
            <h6 style={{"textAlign": "center", "fontWeight":"700"}}>{name}</h6>
            <SModalParagraph>
              {"Please navigate to the Chia Wallet application to confirm this request."}
            </SModalParagraph>
          </SContainer>
        </SModalContainer>
        <Modal.Footer>
          <Button className='modal-button' variant="secondary" onClick={closeModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      ) : 
      result ? (
        <></>
        // <SModalContainer>
        //     <SModalTitle>
        //         {result.valid
        //             ? "JSON-RPC Request Approved"
        //             : "JSON-RPC Request Failed"}
        //     </SModalTitle>
        //     <STable>
        //         {Object.keys(result).map((key) => (
        //             <SRow key={key}>
        //                 <SKey>{key}</SKey>
        //                 <SValue>{result[key].toString()}</SValue>
        //             </SRow>
        //         ))}
        //     </STable>
        // </SModalContainer>
      ) 
      : (
        <SModalContainer>
          <SModalTitle>{"JSON-RPC Request Rejected"}</SModalTitle>
        </SModalContainer>
      )}
    </>
  );
};

export default RequestModal;
