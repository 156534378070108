import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';
import RenderOnLogin from './RenderOnLogin';
import Dashboard from './pages/Dashboard';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import Kyc from './pages/Kyc';
import ReviewOrder from './pages/ReviewOrder';
import Payment from './pages/Payment';
import BankerDashboard from 'console/BankerDashboard';
import ApplicationDetails from 'console/ApplicationDetails';

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/db" element={<RenderOnLogin><Dashboard /></RenderOnLogin>} />
        <Route path="/profile" element={<RenderOnLogin><Profile /></RenderOnLogin>} />
        <Route path="/kyc-verification" element={<RenderOnLogin><Kyc /></RenderOnLogin>} />
        <Route path="/review-order" element={<RenderOnLogin><ReviewOrder /></RenderOnLogin>} />
        <Route path="/payment" element={<RenderOnLogin><Payment /></RenderOnLogin>} />
        {/* Banker Dashboard */}
        <Route path='/applications' element={<RenderOnLogin><BankerDashboard/></RenderOnLogin>}/>
        <Route path='/application-details' element={<RenderOnLogin><ApplicationDetails/></RenderOnLogin>}/>
       </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
