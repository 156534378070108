import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal, Accordion, Table} from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService';
import HashUtil from '../services/HashUtil';

const serverConfig = require("../config/server.js");

const ApplicationDetails = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('mToken'));

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1).split('#')[0];
  console.log();
  console.log('applicationID', applicationID);

  const [appData, setAppData] = useState('');
  const [appDataEmailid, setAppDataEmailid] = useState('');
  const [PIData, setPIData] = useState('');
  const [documentationData, setDocumentationData] = useState('');
  //const [documentationValReport, setDocumentationValReport] = useState('');
  const [docValData, setDocValData] = useState([]);
  const [secondaryDocValData, setSecondaryDocValData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [lastUpdated, setLastUpdated] = useState('');

  /* Passport */
  const [showPassport, setShowPassport] = useState(false);
  const handleClosePassport = () => setShowPassport(false);
  const handleShowPassport = () => setShowPassport(true);
  /* Secondary Doc */
  const [showSecondaryDoc, setShowSecondaryDoc] = useState(false);
  const handleCloseSecondaryDoc = () => setShowSecondaryDoc(false);
  const handleShowSecondaryDoc = () => setShowSecondaryDoc(true);
  const [showSecondaryDocBack, setShowSecondaryDocBack] = useState(false);
  const handleCloseSecondaryDocBack = () => setShowSecondaryDocBack(false);
  const handleShowSecondaryDocBack = () => setShowSecondaryDocBack(true);
  /* Selfie Picture */
  const [showSelfiePicture, setShowSelfiePicture] = useState(false);
  const handleCloseSelfiePicture = () => setShowSelfiePicture(false);
  const handleShowSelfiePicture = () => setShowSelfiePicture(true);

  const[activity, setActivity] = useState([]);
  const[kycDetails, setKycDetails] = useState({});
  
  const formatDateTime = (datestr) => {
    var d = new Date(datestr);
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = d.getFullYear();
    var hh = String(d.getHours()).padStart(2, '0');
    var min = String(d.getMinutes()).padStart(2, '0');
    var ss = String(d.getSeconds()).padStart(2, '0');
    return mm + '/' + dd + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
  }

  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        //handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const kycDetailsGet = () => {
    axios.post(serverConfig.api_base_url + "kyc_details_get",{token: mToken, app_id:applicationID})
      .then(response=>{
        console.log('kyc_details_get', response);
        if(response.data.status === 200) {
          setKycDetails(response.data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setMToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== ''){
      /* application_data_get */
      console.log('mToken', mToken);
      axios.post(serverConfig.api_base_url + "application_data_get",{token:mToken, app_id:applicationID})
      .then(response=>{
        setIsLoading(false);
        console.log(mToken);
        console.log("application_data_get response:", response);
        console.log("application_data_get response:", response.data.output_data);
        console.log(response.data.status);
        if(response.data.status === 200){
          console.log('status 200');
          setAppDataEmailid(response.data.output_data.email_id);
          //console.log("application_data_get response:", JSON.parse(response.data.output_data).app_data);
          if(response.data.output_data.app_data !== null){
            setAppData(response.data.output_data.app_data);
            console.log(JSON.parse(response.data.output_data.app_data.captureSelfiePassport));
            setDocValData(JSON.parse(response.data.output_data.app_data.captureSelfiePassport));
            setSecondaryDocValData(JSON.parse(response.data.output_data.app_data.captureIDSecondaryDoc));
          }
        } else if(response.data.status === 401){
          console.log('status 401');
          getToken();
        }
      })
      .catch(err=>console.log("error is",err));
      console.log(appData);

      axios.post(serverConfig.api_base_url + "customer_activity_log_get",{token: mToken, app_id:applicationID})
      .then(response=>{
        console.log('customer_activity_log_get', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            setActivity(response.data.items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
          } else {
            setActivity([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      kycDetailsGet();

      // Check user privilege
      axios.post(serverConfig.api_base_url + "check_user_privilege", {token: mToken, privilege: "manual_override_kyc"})
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.is_privileged === 0) {
            setUserPrivilegedManualOverrideKyc(false);
          } else if (response.data.is_privileged === 1) {
            setUserPrivilegedManualOverrideKyc(true);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
    }
  }, [mToken]);

  const [userPrivilegedManualOverrideKyc, setUserPrivilegedManualOverrideKyc] = useState(false);
  const [showConfirmationOverrideKyc, setShowConfirmationOverrideKyc] = useState(false);
  const handleCloseConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(false);
  const handleShowConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(true);

  const handleOverrideKyc = () => {
    axios.post(serverConfig.api_base_url + "kyc_approve_manually", {token: mToken, app_id:applicationID})
    .then((response) => {
      if (response.data.status === 200) {
        handleCloseConfirmationOverrideKyc();
        kycDetailsGet();
      } else if (response.data.status === 301) {
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch((err) => console.log("error is", err));
  }
  
  return (
    <>
      <Header isAdmin={true} />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        <section>
          <Container fluid>
            <div className='page-title justify-content-start'>
              <div className='back-link'><Link to="/applications" className="btn-icon"><img src={require('./../assets/images/back-icon.png')} alt="Back" /></Link></div>
              <h1>Application Details</h1>
            </div>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <Row>
              <Col>
                <div className='label'>Full Name of the Applicant</div>
                <div className='value'>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</div>
              </Col>
              <Col>
                <div className='label'>Email Address</div>
                <div className='value'>{appDataEmailid}</div>
              </Col>
              <Col>
                <div className='label'>Mobile </div>
                <div className='value'>{appData.countryCode} {appData.mobileNumber}</div>
              </Col>
              <Col>
                <div className='label'>Date of Birth</div>
                <div className='value'>{appData.dateOfBirth}</div>
              </Col>
              <Col>
                <div className='label'>Country of Birth</div>
                <div className='value'>{appData.countryOfBirth}</div>
              </Col>
              <Col>
                <div className='label'>Citizenship</div>
                <div className='value'>{appData.citizenship}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col className='bdr-right'>
                <div className='label'>Address (Permanent)</div>
                <div className='value'>{appData.addressLine1}, {appData.addressLine2 && appData.addressLine2+', '}{appData.city}, {appData.state}, {appData.country}, {appData.zipcode}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <Row className='align-items-center mb-2'>
              <Col xs="auto"><img src={require('./../assets/images/passport-icon.png')} alt="Passport Icon" /></Col>
              <Col xs="auto">
                <div className='label'><b>Passport Details:</b> <span className={docValData?.resultData?.verificationResult !== 'Approved' ? 'value text-danger' : 'value text-success'}>{docValData?.resultData?.verificationResult}</span></div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.passportImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.passportImg} height="80" alt="Image" className='pp-img' />
                  <span className='ms-3 view' onClick={handleShowPassport}>Click to view</span>
                  <Modal className='show-picture show-pp-picture' show={showPassport} onHide={handleClosePassport} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.passportImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
            </Row>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Extracted Data</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col><span className='label'>MRZ_Data:</span> <span>{docValData?.responseCustomerData?.extractedIdData?.mrzData}</span></Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>Name:</span> {docValData?.responseCustomerData?.extractedPersonalData?.name}</Col>
                    <Col><span className='label'>Gender:</span> {docValData?.responseCustomerData?.extractedPersonalData?.gender}</Col>
                    <Col><span className='label'>IDNumber:</span> {docValData?.responseCustomerData?.extractedIdData?.idNumber}</Col>
                    <Col><span className='label'>Valid ID Number:</span> {docValData?.responseCustomerData?.extractedIdData?.validIdNumber}</Col>
                    <Col><span className='label'>ID Not Expired:</span> {docValData?.responseCustomerData?.extractedIdData?.idNotExpired}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>Nationality:</span> {docValData?.responseCustomerData?.extractedIdData?.nationality}</Col>
                    <Col><span className='label'>IssueDate:</span> {docValData?.responseCustomerData?.extractedIdData?.idIssueDate_doc}</Col>
                    <Col><span className='label'>ExpiryDate:</span> {docValData?.responseCustomerData?.extractedIdData?.idExpirationDate_doc}</Col>
                    <Col><span className='label'>IssuingCountry:</span> {docValData?.responseCustomerData?.extractedIdData?.idIssueCountry}</Col>
                    <Col><span className='label'>DateofBirth:</span> {docValData?.responseCustomerData?.extractedPersonalData?.dob_doc}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZErrorMessages:</span> {docValData?.responseCustomerData?.extractedIdData?.mrzErrorMessage}</Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h6 className='mt-4'>Passport details captured:</h6>
            <Row>
              <Col><span className='label'>Passport ID: </span> {appData.passportNumber}</Col>
              <Col><span className='label'>Issue Date:</span> {appData.IssuanceDate}</Col>
              <Col><span className='label'>Expiry Date:</span> {appData.expirationDate}</Col>
              <Col><span className='label'>Issuing Country:</span> {appData.countryOfIssuance}</Col>
              <Col xs={12} className='mt-2 mb-2'></Col>
              <Col><span className='label'>Hostdata:</span> {appData.captureSelfiePassportChecks}</Col>
              <Col><span className='label'>Hostdata approved:</span> {appData.kycApproved}</Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/secondary-doc-icon.png')} alt="Secondary Document Icon" /></Col>
              <Col xs="auto">
                <div className='label'><b>Secondary Document Details</b></div>
                <div className='value'><span className='value-txt-small'>Front image file uploaded on {formatDateTime(appData.secondaryDocImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSecondaryDoc}>Click to view</span>
                  <Modal className='show-picture' show={showSecondaryDoc} onHide={handleCloseSecondaryDoc} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              <Col xs={{offset:1}}>
                <div className='d-flex align-items-center'>
                  <div className='value me-3'><span className='value-txt-small'>Back image file uploaded on {formatDateTime(appData.secondaryDocBackImgTimestamp)}</span></div>
                  <div className='d-flex align-items-center'>
                    <img src={'data:image/jpeg;base64,'+appData.secondaryDocBackImg} height="80" alt="Image" />
                    <span className='ms-3 view' onClick={handleShowSecondaryDocBack}>Click to view</span>
                    <Modal className='show-picture' show={showSecondaryDocBack} onHide={handleCloseSecondaryDocBack} backdrop="static"keyboard={false} centered>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <img src={'data:image/jpeg;base64,'+appData.secondaryDocBackImg} alt="Image" className='img-fluid' />
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Extracted Secondary Doc Data</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>Name:</span> {secondaryDocValData?.responseCustomerData?.extractedPersonalData?.name}</Col>
                    <Col><span className='label'>Gender:</span> {secondaryDocValData?.responseCustomerData?.extractedPersonalData?.gender}</Col>
                    <Col><span className='label'>IDNumber:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idNumber}</Col>
                    <Col><span className='label'>Valid ID Number:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.validIdNumber}</Col>
                    <Col><span className='label'>ID Not Expired:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idNotExpired}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>ID Country:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idCountry}</Col>
                    <Col><span className='label'>ID State:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idState}</Col>
                    <Col><span className='label'>Postal Code:</span> {secondaryDocValData?.responseCustomerData?.parsedAddress?.postalCode}</Col>
                    <Col><span className='label'>Address:</span> {secondaryDocValData?.responseCustomerData?.extractedPersonalData?.address}</Col>
                    <Col></Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>IssueDate:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idIssueDate_doc}</Col>
                    <Col><span className='label'>ExpiryDate:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idExpirationDate_doc}</Col>
                    <Col><span className='label'>IssuingCountry:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.idIssueCountry}</Col>
                    <Col><span className='label'>DateofBirth:</span> {secondaryDocValData?.responseCustomerData?.extractedPersonalData?.dob_doc}</Col>
                    <Col></Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZErrorMessages:</span> {secondaryDocValData?.responseCustomerData?.extractedIdData?.mrzErrorMessage}</Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/selfie-icon.png')} alt="Selfie Icon" /></Col>
              <Col xs="auto">
                <div className='label'><b>Selfie Picture</b></div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.selfieImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.selfieImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSelfiePicture}>Click to view</span>
                  <Modal className='show-picture' show={showSelfiePicture} onHide={handleCloseSelfiePicture} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.selfieImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <h2>KYC Details</h2>
            <Row>
              <Col>KYC Completed: <b>{kycDetails.kyc_completed === 1 ? <>true</> : <>false</>}</b></Col>
              <Col>KYC Completed timestamp: <b>{kycDetails.kyc_completed_timestamp}</b></Col>
              <Col>No. of KYC attempts: <b>{activity.filter((el) => (el.title === "KYC Failed" || el.title === "KYC Complete")).length}</b></Col>
              <Col>KYC attempt exceeded: <b>{kycDetails.kyc_attempt_exceeded === 1 ? <>true</> : <>false</>}</b></Col>
              <Col xs={12} className='mt-2 mb-2'></Col>
              <Col>KYC Rejected: <b>{kycDetails.kyc_rejected === 1 ? <>true</> : <>false</>}</b></Col>
              <Col>KYC Rejected Message: <b dangerouslySetInnerHTML={{__html: kycDetails.kyc_rejected_message}} /></Col>
              <Col>KYC Rejection Reason: <b>{kycDetails.kyc_rejection_reason}</b></Col>
              <Col></Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <h2><img src={require('./../assets/images/agreements-icon.png')} alt="Agreements Icon" className='me-4' />Agreements &amp; Disclosures</h2>
            <Row>
              <Col>
                <ul>
                  <li><img src={require('./../assets/images/check-icon.png')} alt="Check Icon" className='me-2' />Agrees to Electronic Communication disclosure and consent</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-4 pb-3 bdr-btm">
          <Container fluid>
            <Row>
              <Col xs="auto"><h2>KYC Form Submitted</h2></Col>
              <Col>{appData.submitted}</Col>
            </Row>
          </Container>
        </section>
        <section className='pt-5 pb-5 bdr-btm'>{/* buttons */}
          <Container fluid>
            <Button variant="transparent" className='w-auto' onClick={() => navigate('/applications')}>Cancel</Button>
            {kycDetails.kyc_rejected === 1 && kycDetails.kyc_attempt_exceeded === 1 && kycDetails.kyc_approved_manually !== 1 && <Button variant="primary" className='ms-3 w-auto' onClick={handleShowConfirmationOverrideKyc} disabled={!userPrivilegedManualOverrideKyc}>Manual Override KYC</Button>}
            {kycDetails.kyc_approved_manually === 1 && <span className='ms-3 fs-5'><b>KYC approved manually!</b></span>}
            <span className='float-end'>Last Updated: {lastUpdated}</span>

            <Modal show={showConfirmationOverrideKyc} onHide={handleCloseConfirmationOverrideKyc} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/confirmation-icon.png')} alt="Confirmation Icon" className='me-3' />Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to Manually Override KYC for</p>
                <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseConfirmationOverrideKyc}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleOverrideKyc}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </section>
        <section class="pt-4 pb-3 mt-5">
          <Container fluid>
            <h2>Activity Log</h2>
            {activity.length > 0 ? <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Description</th>
                    <th>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {activity.map((item, index) => {
                    return(<tr key={index}>
                      <td>
                        <b>{item.title}</b>
                      </td>
                      <td><div>{item.description}</div></td>
                      <td><div>{item.updated_at}</div></td>
                    </tr>)
                  })}
                </tbody>
              </Table>
            </> : <div className="text-center pt-5 pb-4">
              <img src={require('./../assets/images/digital-currency-wallet.png')} alt="Digital currency wallet" />
              <p className="txt-gray mt-4">No activity yet</p>
            </div>}
          </Container>
        </section>
      </main>
    </>
  );
}

export default ApplicationDetails;
