import React, { useState, useEffect } from "react";
import { Container, Button, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserService from '../services/UserService';
import Header from "../components/Header";
import Applications from "./Applications";
import Users from "./Users";

const serverConfig = require("../config/server.js");

const BankerDashboard = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("applications");
  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [privilegeAppListRead, setPrivilegeAppListRead] = useState(false);
  const [privilegeUserListRead, setPrivilegeUserListRead] = useState(false);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        //handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log(token);
    console.log(sessionStorage.getItem('token'));
    //setToken(sessionStorage.getItem('token'));

    if(token !== null && token !== undefined){
      //application_list_read - is admin/banker
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('check_user_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
            navigate("/db");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //application_list_read - is admin / banker
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
        console.log('check_user_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeAppListRead(true);
            navigate("/applications");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeAppListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //user_list_read - is admin
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "user_list_read"}).then(response=>{
        console.log('check_user_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeUserListRead(true);
          } else if(response.data.is_privileged === 0) {
            setPrivilegeUserListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Header isLoggedIn={(token !== null && token !== undefined && token !== '') ? true : false} isAdmin={true} />
      <main>
        <Container>
          {privilegeAppListRead &&
            <Tabs
              id="dashboard-tabs"
              activeKey={activeTab}
              onSelect={handleTabChange}
              className="mb-5"
              >
                <Tab eventKey="applications" title="Applications">
                  <Applications handleLogout={handleLogout} isUserListReadPrivileged={privilegeUserListRead} />
                </Tab>
              {privilegeUserListRead &&
                <Tab eventKey="users" title="Users">
                  <Users handleLogout={handleLogout} isUserListReadPrivileged={privilegeUserListRead} />
                </Tab>
              }
            </Tabs>
          }
        </Container>
      </main>
    </div>
  );
};

export default BankerDashboard;
