const copyObjectKeyVals = (obj, h) => {
  let res = {};
  for(let k in h) {
    let v = h[k];
    res[k] = obj[v];
  }
  return res;
};

const copyObjectSubKeyVals = (obj, s, h) => {
  let res = {};
  for(let k in h) {
    let v = h[k];
    res[k] = obj[v][s];
  }
  return res;
};

const HashUtil = {
  copyObjectKeyVals,
  copyObjectSubKeyVals
};

export default HashUtil;
