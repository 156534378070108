import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ReactComponent as ApplicationIcon } from './../assets/images/application-icon.svg';
import { ReactComponent as DeniedApplicationIcon } from './../assets/images/denied-application-icon.svg';
import { ReactComponent as NewApplicationIcon } from './../assets/images/new-applications-icon.svg';
import { ReactComponent as ApprovedApplicationIcon } from './../assets/images/approved-application-icon.svg';

const serverConfig = require("../config/server.js");

const Applications = ({ isUserListReadPrivileged, handleLogout }) => {
  //Invite User Code
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [applications, setApplications] = useState([]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setFormData({});
    setErrors({});
  };

  const handleInviteFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const mToken = sessionStorage.getItem("token");

  const handleSendInviteSubmit = () => {
    console.log("CLICKED");
    let formErrors = {};
    if (!validateEmail(formData.email)) {
      formErrors.email = "Please enter a valid email address";
    }

    console.log("Token", mToken);
    if (mToken !== null && mToken !== undefined) {
      // Check user privilege
      axios
        .post(serverConfig.api_base_url + "user_invite", {
          token: mToken,
          name: formData.fullName,
          email_id: formData.email,
          ph_number : formData.ph_number,
        })
        .then((response) => {
          if (response.data.status === 200) {
            handleClose();
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            // alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  };

  // Code for the Applications

  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setShowInvitePopup(false);
  };
  const handleShowInvitePopup = () => setShowInvitePopup(true);

  const [inviteSentMessage, setInviteSentMessage] = useState("");
  const [showAlreadySentPopup, setShowAlreadySentPopup] = useState(false);
  const handleCloseAlreadySentPopup = () => setShowAlreadySentPopup(false);
  const handleShowAlreadySentPopup = () => setShowAlreadySentPopup(true);

  const [applicationCount, setApplicationCount] = useState({});
  const [showApplications, setShowApplications] = useState("all");
  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    axios
      .post(serverConfig.api_base_url + "customer_application_list_get", {
        token: mToken,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const updatedApplications = response.data.items.map((app) => ({
            ...app,
            actions: (
              <Button variant="link">
                <Link to={"/application-details?" + app.application_id} className='ms-2'><FontAwesomeIcon icon={faEye} /></Link>
              </Button>
            ),
          }));
          setApplications(updatedApplications);
          setTimeout(() => {
            setShowApplications("all");
          }, 100);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  }, []);

  useEffect(() => {
    if (mToken !== null && mToken !== undefined) {
      // Check user privilege
      axios
        .post(serverConfig.api_base_url + "check_user_privilege", {
          token: mToken,
          privilege: "user_invite",
        })
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data.is_privileged === 0) {
              setSendInviteDisabled(true);
            } else if (response.data.is_privileged === 1) {
              setSendInviteDisabled(false);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));

      // Application Counts
      axios
        .post(serverConfig.api_base_url + "get_app_count", {
          token: mToken,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setApplicationCount(response.data.output);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            // alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [mToken, showApplications]);

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = { token: mToken, ...inputs, force_flag: 0 };
    axios
      .post(serverConfig.api_base_url + "submit_data", formdata)
      .then((response) => {
        if (response.data.status === 200) {
          handleCloseInvitePopup();
        } else if (response.data.status === 210) {
          setInviteSentMessage(response.data.message);
          handleShowAlreadySentPopup();
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleProceedSendInvite = (e) => {
    e.preventDefault();
    var formdata = { token: mToken, ...inputs, force_flag: 1 };
    axios
      .post(serverConfig.api_base_url + "submit_data", formdata)
      .then((response) => {
        if (response.data.status === 200) {
          handleCloseAlreadySentPopup();
          handleCloseInvitePopup();
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const refreshAppList = (e) => {
    axios
      .post(serverConfig.api_base_url + "customer_application_list_get", {
        token: mToken,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const updatedApplications = response.data.items.map((app) => ({
            ...app,
            actions: (
              <Link to={"/application-details?" + app.application_id} className='ms-2'><FontAwesomeIcon icon={faEye} /></Link>
            ),
          }));
          setApplications(updatedApplications);
          setTimeout(() => {
            setShowApplications("all");
          }, 100);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const columns = [
    { label: "ID", field: "application_id", },
    { label: "Name", field: "name", },
    { label: "Email", field: "email_id", },
    { label: "Status", field: "status", },
    { label: "Actions", field: "actions" },
  ];

  const data = {
    columns: columns,
    rows: applications,
  };

  return (
    <div>
      <div className="page-heading">
        <h1>Applications</h1>
        {/* <Button onClick={handleShow} className="mt-2 w-auto" disabled={sendInviteDisabled}>
          Invite User
        </Button> */}
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter full name"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter email"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formMobileNumber" className="mt-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  name="ph_number"
                  value={formData.ph_number || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter mobile number"
                />
              </Form.Group>

              {/* <Form.Group controlId="formRole" className="mt-3">
                <Form.Label>Select Role</Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  value={formData.role || ""}
                  onChange={handleInviteFormChange}
                >
                  <option value="">Select...</option>
                  <option value="Admin">Admin</option>
                  <option value="Banker">Banker</option>
                  <option value="Customer">Customer</option>
                </Form.Control>
              </Form.Group> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSendInviteSubmit}>
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <>
        <section className="pt-4">
          <Row className="application-stats">
          <>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("all")}
                  >
                    <h4>Total Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"total" in applicationCount
                            ? applicationCount.total
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("open")}
                  >
                    <h4>Open</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"open" in applicationCount
                            ? applicationCount.open
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <NewApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("completed")}
                  >
                    <h4>Completed</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"completed" in applicationCount
                            ? applicationCount.completed
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApprovedApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            {/* {isUserListReadPrivileged ? (
              <>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("all")}
                  >
                    <h4>Total Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"total" in applicationCount
                            ? applicationCount.total
                            : "2"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("unassigned")}
                  >
                    <h4>Open</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"unassigned" in applicationCount
                            ? applicationCount.unassigned
                            : "2"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <NewApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("completed")}
                  >
                    <h4>Completed</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"completed" in applicationCount
                            ? applicationCount.completed
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApprovedApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("all")}
                  >
                    <h4>Applications Assigned to Me</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"total" in applicationCount
                            ? applicationCount.total
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("under_review")}
                  >
                    <h4>Applications Under Review</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"under_review" in applicationCount
                            ? applicationCount.under_review
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <NewApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("completed")}
                  >
                    <h4>Applications Completed</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"completed" in applicationCount
                            ? applicationCount.completed
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <ApprovedApplicationIcon />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            )} */}
          </Row>
        </section>
        <section>
          <Modal
            show={showInvitePopup}
            onHide={handleCloseInvitePopup}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Form onSubmit={handleSendInvite}>
              <Modal.Header closeButton>
                <Modal.Title>New User Invite</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="app_email_id"
                    value={inputs.app_email_id || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Mobile No."
                    name="phone_number"
                    value={inputs.phone_number || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseInvitePopup}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="ms-3">
                  <img
                    src="https://via.placeholder.com/30"
                    alt="Sent Invite Icon"
                    className="me-2"
                  />
                  Send Invite
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={showAlreadySentPopup}
            onHide={handleCloseAlreadySentPopup}
            backdrop="static"
            keyboard={false}
            centered
            className="content-text"
          >
            <Modal.Body>
              <p>{inviteSentMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="transparent"
                onClick={handleCloseAlreadySentPopup}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="ms-3"
                onClick={handleProceedSendInvite}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <section>
          <div className="datatable datatable-app-list">
            <h2 className="h3">
              Total Applications{" "}
              <button className="ms-3" onClick={refreshAppList}>
                <FontAwesomeIcon icon={faSync} />
              </button>
            </h2>
            <MDBDataTable striped bordered hover data={data} />
          </div>
        </section>
      </>
    </div>
  );
};

export default Applications;
