import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import { Button, Form, Row, Col } from 'react-bootstrap';
import UserService from './../../services/UserService';

const serverConfig = require("./../../config/server.js");

export default function CreditCard({ handlePaymentSet }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    const billing_details = {
      name: UserService.getWelcomeName(),
      email: UserService.getUsername(),
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details,
    });
    if (error) {
      console.error('Error creating payment method:', error);
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }
    try {
      const response = await fetch(serverConfig.fapi_base_url + 'submit_payment', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: UserService.getWelcomeName(),
          email: UserService.getUsername(),
          //name: "Ranjith123456",
          //email: "ranjithv123456@gmail.com",
          //address: {line1: "house no. 1", city:"Bangalore", state: "Karnataka", postal_code: "560078", country: "IN"},
          payment_method: paymentMethod.id,
          promotion_code: couponCode || undefined, // Optional promotion code
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error('Error creating subscription:', data.error);
        setMessage(data.error);
      } else if (data.clientSecret) {
        const result = await stripe.confirmCardPayment(data.clientSecret);
        if (result.error) {
          console.error('Error confirming card payment:', result.error);
          setMessage(result.error.message);
        } else {
          console.log('Payment successful:', result.paymentIntent);
          setMessage('Payment successful');
          handlePaymentSet(data.subscription_ID); // Callback to handle post-payment actions
        }
      } else if (data.message) {
        console.log(data.message);
        if(data.message === "Invoice already paid"){
          console.log('Payment successful:', "Invoice already paid");
          setMessage('Payment successful');
          handlePaymentSet(data.subscriptionID);
        } else {
          setMessage(data.message);
        }
      }
    } catch (error) {
      console.error('Error handling request:', error);
      setMessage('An unexpected error occurred.');
    }
    setIsProcessing(false);
  };
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className='mb-3'>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" readOnly={true} value={UserService.getWelcomeName()} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" readOnly={true} value={UserService.getUsername()} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Coupon Code</Form.Label>
          <Row>
            <Col>
              <Form.Control
                type="text"
                name="coupon_code"
                className='custom-input'
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Card Details</Form.Label>
          <CardElement id="payment-element" />
        </Form.Group>
      </div>
      {message && <div id="payment-message" className="mt-3 txt-red">{message}</div>}
      <div className="payment-btn-container mt-3">
        <Button type="submit" variant="primary" disabled={isProcessing || !stripe || !elements} id="submit">
          {isProcessing ? "Processing ... " : "Submit Payment"}
        </Button>
      </div>
    </form>
  );
}