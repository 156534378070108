import React, { useMemo, useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

const useOptions = () => {
  const fontSize = "14px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = (props) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const handleClosePaymentSuccessModal = () => {
    setShowPaymentSuccessModal(false);
    navigate("/kyc-verification");
  }
  const handleShowPaymentSuccessModal = () => setShowPaymentSuccessModal(true);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });
    console.log(error);
    console.log(paymentMethod);
    if(!error){
      try {
        setIsProcessing(true);
        /* const { errorPI, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.origin}/completion`,
            //redirect: "if_required",
          },
        }); */
        const {id} = paymentMethod;
        console.log(id);
        const { errorPI, paymentIntent } = await stripe.confirmCardPayment(props.client_secret, {
          payment_method: id,
        });
        console.log(errorPI);
        console.log(paymentIntent);
        console.log(error);

        stripe
        .confirmCardPayment(props.client_secret, {
          payment_method: id,
        })
        .then(function(result) {
          console.log(result);
          // Handle result.error or result.paymentIntent
        });

        if(errorPI){
          setMessage(errorPI.message);
        } else if(paymentIntent && paymentIntent.status === 'succeeded'){
          setMessage("Payment status :"+ paymentIntent.status);
        } else {
          setMessage("Unexpected state");
        }

        setIsProcessing(false);
      } catch (error) {
        console.log("Error", error)
      }
    } else {
      console.log(error.message);
      setMessage(error.message);
    }

    
  };

  return (
    <form onSubmit={handleSubmit} className="form-payment">
      ---{props.client_secret}
      <label>
        Card number
        <CardNumberElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
            setMessage(null);
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <Row>
        <Col>
          <label>
            Expiration
          </label>
          <CardExpiryElement
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
                setMessage(null);
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
        </Col>
        <Col>
          <label>
            CVV

          </label>
          <CardCvcElement className="form-control"
              options={options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
                setMessage(null);
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
        </Col>
      </Row>
      <div className="mt-3 txt-red">{message ? message : <>&nbsp;</>}</div>
      <div className="payment-btn-container"><Button type="submit" variant="primary" disabled={!stripe}>{isProcessing ? "Processing ... " : "Submit Payment"}</Button></div>
      <Modal show={showPaymentSuccessModal} onHide={handleClosePaymentSuccessModal} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="text-center">Payment successful!</div></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClosePaymentSuccessModal}>
            Proceed with KYC verification
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default SplitForm;