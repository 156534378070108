import React from "react";

const ConsentCommunications = () => {
  return (
    <>
    <h1>Terms and Conditions</h1>
    <h3>Electronic Communication Disclosure and Consent</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lectus vel nisi consectetur euismod a at est. Sed rhoncus, leo nec viverra vehicula, mi sem tempor neque, vel fringilla nibh massa in lorem. Curabitur lobortis risus fringilla elementum euismod. Pellentesque tortor lorem, porta vel ultrices id, ullamcorper eget leo. Nullam bibendum orci laoreet nunc pharetra, imperdiet porttitor velit finibus. In lacus neque, accumsan nec metus eget, volutpat maximus enim. Integer pulvinar sagittis velit, quis hendrerit urna fringilla nec. Morbi tincidunt ac magna non facilisis. Curabitur varius vehicula feugiat. Fusce tempus laoreet ante, ut sagittis diam commodo ut. Mauris rhoncus facilisis semper. Quisque et erat at neque faucibus ultricies. Mauris et feugiat ligula, nec pulvinar nisi. Duis rutrum enim ex. Vestibulum eleifend dolor felis, eu efficitur metus tincidunt vitae. Vestibulum rutrum imperdiet ultricies.</p>
    <p>Proin finibus diam sapien, quis ornare elit consectetur nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas porttitor blandit pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel lacus posuere, efficitur velit vel, mollis nisi. Proin aliquet neque id pulvinar semper. Nullam at porttitor purus. Integer eu orci ut neque euismod venenatis eget sit amet libero. Suspendisse laoreet leo vitae felis volutpat pellentesque. Nam finibus odio ac nisi accumsan volutpat. Suspendisse dolor ipsum, congue ornare nisi vel, interdum rutrum dui.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lectus vel nisi consectetur euismod a at est. Sed rhoncus, leo nec viverra vehicula, mi sem tempor neque, vel fringilla nibh massa in lorem. Curabitur lobortis risus fringilla elementum euismod. Pellentesque tortor lorem, porta vel ultrices id, ullamcorper eget leo. Nullam bibendum orci laoreet nunc pharetra, imperdiet porttitor velit finibus. In lacus neque, accumsan nec metus eget, volutpat maximus enim. Integer pulvinar sagittis velit, quis hendrerit urna fringilla nec. Morbi tincidunt ac magna non facilisis. Curabitur varius vehicula feugiat. Fusce tempus laoreet ante, ut sagittis diam commodo ut. Mauris rhoncus facilisis semper. Quisque et erat at neque faucibus ultricies. Mauris et feugiat ligula, nec pulvinar nisi. Duis rutrum enim ex. Vestibulum eleifend dolor felis, eu efficitur metus tincidunt vitae. Vestibulum rutrum imperdiet ultricies.</p>
    <p>Proin finibus diam sapien, quis ornare elit consectetur nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas porttitor blandit pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel lacus posuere, efficitur velit vel, mollis nisi. Proin aliquet neque id pulvinar semper. Nullam at porttitor purus. Integer eu orci ut neque euismod venenatis eget sit amet libero. Suspendisse laoreet leo vitae felis volutpat pellentesque. Nam finibus odio ac nisi accumsan volutpat. Suspendisse dolor ipsum, congue ornare nisi vel, interdum rutrum dui.</p>
    </>
  );
};

export default ConsentCommunications;