import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  useStripe,
  useElements,
  FpxBankElement
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

const useOptions = () => {
  const fontSize = "14px";
  const options = useMemo(
    () => ({
      accountHolderType: "individual",
      style: {
        base: {
          fontSize,
          color: "#000000",
          fontFamily: "Poppins, sans-serif",
          height: "21px",
          lineHeight: "21px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const FpxBankForm = () => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [errorMsg, setErrorMsg] = useState(null);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const handleClosePaymentSuccessModal = () => {
    setShowPaymentSuccessModal(false);
    navigate("/kyc-verification");
  }
  const handleShowPaymentSuccessModal = () => setShowPaymentSuccessModal(true);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    //const payload = await stripe.createPaymentMethod({
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "fpx",
      fpx: elements.getElement(FpxBankElement),
      billing_details: {
        name: event.target.name.value
      }
    });
    //console.log("[PaymentMethod]", payload);
    console.log('error', error);
    console.log('paymentMethod', paymentMethod);

    if(!error){
      try {
        const {id} = paymentMethod;
        console.log(id);
        /* const response = await axios.post("http://localhost:4000/payment", {
            amount: 100,
            currency: 'usd',
            id
        })
        if(response.data.success){
            console.log("Successful Payment")
            setSuccess(true);
            //handleShowPaymentSuccessModal();
        } */
        handleShowPaymentSuccessModal();
      } catch (error) {
          console.log("Error", error);
      }
    } else {
      console.log(error.message);
      setErrorMsg(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-payment">
      <label>
        Name
        <input name="name" type="text" placeholder="Jenny Rosen" className="form-control" required />
      </label>
      <label>
        FPX Bank
        <FpxBankElement
          className="FpxBankElement"
          options={options}
          onReady={() => {
            console.log("FpxBankElement [ready]");
          }}
          onChange={event => {
            console.log("FpxBankElement [change]", event);
          }}
          onBlur={() => {
            console.log("FpxBankElement [blur]");
            setErrorMsg(null);
          }}
          onFocus={() => {
            console.log("FpxBankElement [focus]");
          }}
        />
      </label>
      <div className="mt-3 txt-red">{errorMsg ? errorMsg : <>&nbsp;</>}</div>
      <div className="payment-btn-container"><Button type="submit" variant="primary" disabled={!stripe}>Submit Payment</Button></div>
      <Modal show={showPaymentSuccessModal} onHide={handleClosePaymentSuccessModal} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body><h5 className="text-center">Payment successful!</h5></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClosePaymentSuccessModal}>
            Proceed with KYC verification
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default FpxBankForm;
