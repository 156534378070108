import styled from "styled-components";
import { fonts } from "../styles";
import Button from "../components/Button";
import Column from "../components/Column";
import Wrapper from "../components/Wrapper";

export const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 2vh;
  text-align: center;
`;

export const SContent = styled(Wrapper as any)`
  width: 100%;
  height: 100%;
  padding: 0 8px;
`;

export const SLanding = styled(Column as any)`
  /* height: 600px; */
`;

export const SButtonContainer = styled(Column as any)`
    width: 213px;
    margin-left: 100px;
    margin-top: -55px;
`;

export const SConnectButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  margin: 12px 0;
`;

export const SAccountsContainer = styled(SLanding as any)`
  height: 100%;
  padding-bottom: 30px;
  & h3 {
    padding-top: 30px;
  }
`;

export const SToggleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  & > p {
    margin-right: 10px;
  }
`;

export const SFullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const SAccounts = styled(SFullWidthContainer)`
  justify-content: space-between;
  & > div {
    margin: 12px 0;
    flex: 1 0 100%;
    @media (min-width: 648px) {
      flex: 0 1 48%;
    }
  }
`;
