import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import Header from "../components/Header.jsx";
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const Profile = () => {

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  //const[activity, setActivity] = useState([]);
  const[activity, setActivity] = useState([
    {icon:'logged-in-icon.png', title:'Logged in', updated_at:'April 3, 2023 2:16PM'},
    {icon:'logged-out-icon.png', title:'Logged out', updated_at:'March 3, 2023 2:16PM'},
    {icon:'logged-in-icon.png', title:'Logged in', updated_at:'February 3, 2023 2:16PM'},
  ]);

  const [showResetPwdModal, setShowResetPwdModal] = useState(false);
  const handleCloseResetPwdModal = () => setShowResetPwdModal(false);
  const handleShowResetPwdModal = () => setShowResetPwdModal(true);

  useEffect(() => {
    
  },[]);

  return (
    <div className="body-wrap bg-color8">
      <Header />
      <main>
        <Container fluid>
          <h1>Profile</h1>
          <section>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" readOnly={true} value={UserService.getWelcomeName()} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" readOnly={true} value={UserService.getUsername()} />
            </Form.Group>
            {/* <div className="mb-3"><Button variant="plain" onClick={handleShowResetPwdModal}><u>Reset password</u></Button></div> */}
          </section>
          {/* <div className="box-link mb-4">
            <Row>
              <Col className="d-inline-flex align-items-center">
                <img src={require('./../assets/images/chia-logo.png')} alt="Chia logo" />
              </Col>
              <Col xs="auto">
                <div className="fs-1rem">ChiaWallet</div>
                <div className="txt-gray">1234 4580 **</div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="activity bg-white">
              <h6 className="mb-3"><b>Log Activity</b></h6>
              {activity.length > 0 ? <>
                <ul className="activity-list">
                  {activity.map((item, index) => {
                    return(<li key={index}>
                      <div className="icon"><img src={require(`./../assets/images/${item.icon}`)} /></div>
                      <div className="details">
                        <div className="d-flex justify-content-between">
                          <h6><b>{item.title}</b></h6>
                          <span>{item.updated_at}</span>
                        </div>
                        {item.did && <div className="mt-2"><b>{item.did}</b></div>}
                      </div>
                    </li>)
                  })}
                </ul>
                <div className="mt-4"><Button variant="primary" onClick={handleLogout}>Log Out</Button></div>
              </> : <div className="text-center pt-5 pb-4">
                <img src={require('./../assets/images/digital-currency-wallet.png')} alt="Digital currency wallet" />
                <p className="txt-gray mt-4">Don't have activity yet</p>
              </div>}
            </Col>
          </Row> */}
        </Container>

        {/* Reset password */}
        <Modal show={showResetPwdModal} onHide={handleCloseResetPwdModal} backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <br/><br/><br/>
              <div className="mb-3"><img src={require('./../assets/images/sent-password.png')} alt="Mail icon" className="img-fluid" /></div>
              <h6><b>We sent a password reset to your email.</b></h6>
              <br/><br/><br/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="transparent" onClick={handleCloseResetPwdModal}>Close</Button> */}
            <Button variant="primary">Check email</Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
}

export default Profile;
