import React from "react";

const Disclosures = () => {
  return (
    <>
    <h2><u>SaaS Software End User License Agreement</u></h2>
    <p>
        This SaaS Software End User License Agreement (this <u>"Agreement"</u>) is entered into on this date
        of your account registration (the <u>“Effective Date”</u>) by and between you, and   Inc. (<u>“We”</u> or <u>“Us”</u>).
        This Agreement  governs your  access or  use  of  website, technology platforms, content, and
        products (collectively, the <u>“Software”</u>), and describe the services (the <u>“Services”</u>) available from
        us through the Software.<br></br>
        <br></br>
        In order to use the Software and Services, you must agree to this Agreement.   If you do not agree
        to this Agreement, you are not permitted to use the Software and Services.   BY USING THE
        SOFTWARE AND SERVICES, YOU (1) ACKNOWLEDGE THAT YOU HAVE READ AND
        UNDERSTAND THIS AGREEMENT AND AGREED TO BE BOUND BY THESE TERMS,
        (2)  ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE
        BOUND BY THE PROVISIONS OF OUR PRIVACY POLICY  AND (3) REPRESENT THAT
        YOU ARE AUTHORIZED TO AGREE TO THIS AGREEMENT ON BEHALF OF YOUR
        COMPANY (IF APPLICABLE).   You acknowledge and agree that we will treat your use of the
        Software   and   Services   as   acceptance   of   the   Agreement   from   the   time   of   your   first   use.
        Notwithstanding anything else in the Agreement, we may, in our sole discretion, refuse you or any
        other person access to the Services or the Software at any time and for any reason. <br></br>
        <br></br>
        <b>1. <u>General.</u></b><br></br>
        The term “you” and “your” for purposes of this Agreement, means both you in your individual
        capacity, and the company or other business entity whom you represent and on whose behalf you
        use the Services under actual or apparent authority.  We may add to, change or remove any part of
        this Agreement at any time by providing you notice via email.   By continuing to use the Services,
        you are indicating your acceptance of such changes.  Any such revisions or updates are effective
        immediately when we notify you via email.<br></br>
        <br></br>
        <b>2. <u>Know Your Customer (“KYC”) Services.</u></b><br></br>
        Subject to the terms of this Agreement, we will assist you with verifying your identification and
        credential  via our proprietary software platform called “Manotoko”  (the <u>“Services”</u>) to satisfy
        KYC requirements so that you may participate in the trading of tokenized equity or other securities
        with Chia Network Inc., a Delaware corporation (<u>“Chia”</u>).<br></br>
        <br></br>
        (a) You will provide via the Software certain necessary information, photo identification, and
        other documents (such as your  driver’s license, passport, birth certificate, certificate of
        naturalization or citizenship) as necessary for us to verify your identity (collectively, the
        “ID Data”).<br></br>
        <br></br>
        (b) You are responsible for ensuring that your ID Data is up to date, complete, and accurate.
        You will be responsible for any delays, additional costs or other liabilities caused by or
        associated with your failure to fulfill your obligations under this section.  We (and Chia)
        will rely on your ID Data to perform the Services.<br></br>
        <br></br>
        (c) You consent to us performing verification of your identification (individual and business)
        for   KYC   and   fraud   prevention   purposes,   and   for   conducting   applicable   background
        checks in compliance with applicable law to ensure and verify that (i) you are not on any
        sanctions or embargo watch lists of any law enforcement agency or other government
        agency (whether municipal, county, state, federal, international, or foreign), such as the
        U.S. Treasury Department's Office of Foreign Asset Control (<u>"OFAC"</u>) or "Specially
        Designated National and Blocked Persons List"(<u>"SDN List"</u>), or (ii) otherwise subject to
        any  law   enforcement   or   governmental   action  or   investigation  that   would  make   you
        ineligible to enter into a business transaction with Chia, including the   trading of any
        tokenized equity or other securities.  You also consent to us reviewing whether you are
        subject to potential risk associated with a person given their political status and visibility
        as a “Politically Exposed Person”, or familial ties with a Politically Exposed Person or
        individual subject to a OFAC or SDN List.<br></br>
        <br></br>
        (d) You represent and warrant that neither your ID Data nor your submission,  or otherwise
        making   available   your   ID   Data   nor   our   use   of   your   ID   Data   as   permitted   by   this
        Agreement will infringe, misappropriate, or violate a third party’s intellectual property or
        proprietary rights, or rights of publicity or privacy, or result in the violation of any
        applicable law or regulation.<br></br>
        <br></br>
        (e) We reserve the right to withdraw or modify the Software, and any component. service or
        material we provide on the Software, in our sole discretion without notice. We will not be
        liable if for any reason all or any part of the Software is unavailable at any time or for any
        period.<br></br>
        <br></br>
        <b>3. <u>Registration for User Account; Security.</u></b><br></br>
        <br></br>
        You are responsible for ensuring that all persons who access the Software using your user ID
        and/or device are aware of  the terms of this Agreement and will comply with them. <br></br>
        <br></br>
        (a) <u>User Accounts</u>. In order to use the Services, you must register for and maintain an active
        personal user account (<u>“Account”</u>).  In order to create an Account, you will be required to
        provide the following information to us: (1) your first and last name; (2) your e-mail
        address; (3) phone numbers; and (4) various information about your business, such as legal
        business name, business address, employer identification number, and type of business.<br></br> 
        <br></br>
        (b) By creating an Account, you represent and warrant that: (1) all information you submit in
        connection with the opening and use of your Account is true, accurate, current, and
        complete; (2) you have the authority to enter into the Agreement and bind the company or
        other business entity on whose behalf you access the Software and use the Services; (3) the
        street address you use when registering to use the Services is the principal place of
        business of the company or other business entity on whose behalf you access the Software
        and use the Services; (4) your use of the Software or Services does not and will not violate
        any applicable law, rule or regulation; (5) you will promptly notify us if your information
        changes so that we may update your records and ensure that your information remains
        current; and (6) you are aged 18 or older; the Services are not intended for persons under
        18. We reserve the right to verify the identity of any registered user, although we have no
        obligation to do so.<br></br>
        <br></br>
        (c) You   are   solely   responsible   for   maintaining   the   security   and   confidentiality   of   the
        information in your Account, including, without limitation, your user name and password,
        and for any and all activity that occurs through your Account as a result of your failure to
        keep this information secure and confidential. You hereby agree to notify us immediately
        if you become aware of any unauthorized use of your Account, user name or password, or
        any other breach of security in connection therewith. You may be held liable for losses
        incurred by us or any third party due to someone else using your Account, user name or
        password   as   a   result   of   your   failing   to   keep   your   Account   information   secure   and
        confidential. You are strictly prohibited from using anyone else's Account, user name or
        password at any time and for any reason. You may not assign, sell or transfer your
        Account, user name or password to any other person. We are not liable to you or any third
        party for your failure to comply with your obligations under this paragraph.<br></br>
        <br></br>
        (d) We require a valid, current email address to communicate with you and to identify you in
        the Software. You authorize us to communicate with you using the email address that you
        provide.<br></br>
        <br></br>
        (e) We have the right to disable any Account at any time in our sole discretion for any or no
        reason, including if, in our opinion, you have violated any provision of the Agreement.<br></br>
        <br></br>
        <b>4. <u>Prohibited Uses</u></b><br></br> 
        <br></br>
        You hereby represent and warrant that you will not permit or induce any third party to: (a)
        attempt to disable or circumvent any security mechanisms used by the Software or Services or
        otherwise attempt to gain unauthorized access to any portion or feature of the Software or
        Services, or any other systems or networks connected to the Software or Services, or to any third
        party server, by hacking, password "mining", or any other illegal means; (b) use any "deep-link",
        "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology,
        or any comparable manual process, to access, acquire, copy, or monitor any portion of the
        Software or Services; (c) use any device, software or routine to interrupt or interfere, or attempt
        to interrupt or interfere with, the proper operation and working of the Software or Services or any
        transaction being conducted on the Software or through the Services, or with any other person's
        use of the Software or Services; (d) breach any security measures implemented on the Software
        or in the Services; (e) track or seek to trace any information on any other person who visits our
        website, or platform Services; (f) forge headers or otherwise manipulate identifiers in order to
        disguise your identity, or the origin of any message or other communication that you send to us,
        or any third party in connection with the Services; (g) pretend that you are, or that you represent,
        someone else, or impersonate any other person; (h) use the Software or Services for, or in
        connection with, any illegal purpose, to solicit, facilitate, encourage, condone, or induce any
        illegal   activity,   or   as   otherwise   prohibited   by   the   Agreement   or   applicable   laws,   rules   or
        regulations, including, without limitation, laws applicable to the export of software and data,
        consumer protection, unfair competition, anti-discrimination or false advertising; (i) upload or
        otherwise process any malicious content to, or through, the Services; or (j) copy, modify, create a
        derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code
        of any proprietary software used to provide, maintain, or otherwise applicable to the Software
        and/or Services, or made available to you in connection with the Software and/or Services.<br></br>
        <br></br>
        You will promptly notify us if you become aware that the Software or Services are being used
        for any other illegal or unauthorized purpose.  You are also solely responsible for ensuring that
        your use of the Services complies with all applicable laws, rules and regulations of the place
        from where you access the Services and/or use such products and services.<br></br>
        <br></br>
        You also represent that you are not an individual or an individual employed by or associated with
        an entity identified on the US Department of Commerce’s Denied Persons or Entity List, the US
        Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, or the
        Department of State’s Debarred Parties List, or otherwise ineligible to receive items subject to
        US Export control laws and regulations, or other economic sanctions of any sovereign nation.
        You may not access or use the Services from within any country that is subject to United States
        export restrictions (currently including, but not necessarily limited to, Iran, Syria, North Korea,
        Libya, and Sudan).<br></br>
        <br></br>
        You acknowledge that our KYC Services do not constitute a “consumer report” for purposes of
        the Fair Credit Reporting Act (FCRA).  You may not use the Services in whole or in part as a
        factor in determining consumer eligibility for credit, insurance, employment, tenancy, or any
        another FCRA purpose.<br></br>
        <br></br>
        <b>5. <u>Internet Access and Devices.</u></b><br></br>
        <br></br>
        You are responsible for obtaining the internet access necessary to use the Services. You are
        responsible for acquiring and updating compatible hardware or devices necessary to access and
        use the Services and any updates thereto. We do not guarantee that the Services, or any portion
        thereof, will function on any particular hardware or device. In addition, the Services may be
        subject   to   malfunctions   and   delays   inherent   in   the   use   of   the   Internet   and   electronic
        communications.<br></br>
        <br></br>
        <b>6. <u>Intellectual Property Rights.</u></b><br></br>
        <br></br>
        (a) You hereby acknowledge and agree that we (or our licensors) own all legal right, title and
        interest in and to the Software and Services, including, without limitation, all intellectual
        property or other proprietary rights which subsist in the Software (whether such rights are
        registered or unregistered, and wherever in the world those rights may exist). As between
        you and us, all materials on the Software, including, but not limited to, graphics, user and
        visual interfaces, images, software, advertising tools, applications, and text, as well as the
        design, structure, selection, coordination, expression, "look and feel", and arrangement of
        the   Software   and   Services,   and   their   content   except   for   any   of   your   User   Content
        (collectively the <u>“Material”</u>), and the domain names, trademarks, service marks, proprietary
        logos and other distinctive brand features found on the Software and Services, are all
        owned by us or our licensors.<br></br>
        <br></br>
        (b) Nothing in the Agreement gives you any right to use any of our trade names, trademarks,
        service marks, logos, domain names, and other distinctive brand features. All rights not
        expressly granted by us under the Agreement are reserved. There are no implied rights.<br></br>
        <br></br>
        (c) Subject to your compliance with the Agreement, we  hereby grant you a limited, personal,
        worldwide, royalty-free, non-transferable, non-sublicensable and non-exclusive license to
        use the Software and Services for internal business use only to allow us (and Chia) to
        verify your identity and to confirm that you are not barred by law to engage in any
        financial   transactions   with   Chia   in   violation   of   anti-money   laundering   laws   or   any
        government embargo list, such   as  the OFAC or SDN List.   The license granted in this
        subsection is for the sole purpose of enabling you to use and enjoy the benefit of the
        Software and Services as provided by us, in the manner permitted by the Agreement.<br></br> 
        <br></br>
        (d) All Material is provided to you “AS IS”. You may access Material for your information
        and personal use solely as intended through the provided functionality of the Software and
        Services and as permitted under the Agreement. You shall not copy, reproduce, distribute,
        transmit, broadcast, download display, sell, license, or otherwise exploit any Material for
        any other  purposes without  our  prior  written consent. You agree  to comply with all
        applicable laws and regulations in your use of any Software and Material. Any use of the
        Software not expressly permitted by the Agreement is a breach of the Agreement and may
        violate copyright, trademark, and other laws.<br></br>
        <br></br>
        (e) You further acknowledge and agree that we are not liable in any way to you or any third
        party, for any third-party content or Material made available to you through the Services,
        including, without limitation, any errors or omissions in such content or Material, or for
        any loss or damage of any kind incurred as a result of the use or reliance such content or
        Material. You agree to evaluate and bear all risks and responsibility associated with the
        use or reliance on any such content or Material that you obtain through your use of the
        Services<br></br>
        <br></br>
        <b>7. <u>Your User Content & License from You.</u></b><br></br>
        <br></br>
        (a) You hereby agree that you are solely responsible and liable for any and all information,
        data files, ID Data, written text, computer files, images, messages, communications, and
        other content (collectively <u>“User Content”</u>) that you collect, upload, store, transmit,
        display, publish, disclose, or otherwise use in connection with the Services, and for the
        consequences of your actions in connection with such User Content and your use of the
        Services, and our use of your User Content on your behalf.<br></br>
        <br></br>
        (b) Without limiting the foregoing, your User Content includes, without limitation: (a) all
        information you provide when registering for an Account; and (b) all information you
        provide, post, or upload in connection with the Software, including your ID Data, and
        any personally identifiable information.<br></br>
        <br></br>
        (c) You   agree   that   we   have   no   responsibility   to   you,     or   to   any   other   third   party   in
        connection with your User Content. You are solely responsible for any losses or damage
        suffered by us, or any other third party in connection with your User Content. You
        hereby represent and warrant that you will not collect, store, transmit, publish, provide,
        or otherwise use any User Content in connection with the Services that: (a) violates the
        Agreement;   (b)   is   defamatory,   libellous,   abusive,   illegal,   profane,   indecent,
        pornographic, obscene, hateful, offensive, harassing, or threatening in any way; (c) is
        fraudulent or otherwise constitutes or encourages conduct that would be considered a
        criminal   offense   or   give   rise   to   civil   liability,   or   otherwise   intentionally   or
        unintentionally violates, or induces the violation of, any law, rule or regulation; (d)
        infringes, misappropriates, or otherwise violates any third party’s copyright, trademark,
        patent, trade secret, or other personal or proprietary right; (e) invades or interferes with
        the rights of privacy or publicity of any person; or (f) contains a virus, malicious code or
        any other harmful component.<br></br>
        <br></br>
        (d) You agree to immediately take down, delete or modify any User Content that is uploaded
        or stored through the Services that violates the Agreement or any applicable laws, rules,
        or regulations, including pursuant to a take down, deletion or modification request from
        us.  We assume no liability or responsibility arising from your activities in connection
        with the Services, including, without limitation, User Content that you collect, store,
        transmit, publish, process, disclose, or otherwise use in connection with the Services.<br></br>
        <br></br>
        (e) We claim no ownership or control over your right, title and interest in your User   
        Content. You retain copyright and any other proprietary rights that you already hold in
        your User Content, and you are solely responsible for protecting those rights, as you
        deem   appropriate.   By   collecting,   submitting,   storing,   transmitting,   publishing,
        displaying, or otherwise using your User Content in connection with the Services, you
        hereby   grant   us   (including   our   third   party   service   providers   and   contractors)   a
        worldwide,   royalty-free,   fully-paid-up,   non-exclusive,   transferable,   sub-licensable,
        perpetual, irrevocable license to reproduce, adapt, reformat, modify, perform, display,
        distribute, translate, create derivative works from, and otherwise use such User Content
        for the purpose of enabling us to provide the Services, including, without limitation,
        storing and retrieving the User Content, making the User Content available through the
        Services,   reformatting   and   modifying   the   User   Content   for   technical   display   and
        transmission, conforming the User Content to the limitation and terms of the Services,
        and   any   other   use   related   to   the   maintenance,   provision   and   improvement   of   the
        Software and Services (i.e. analytics and data analysis).<br></br>
        <br></br>
        (f) You hereby consent to us sharing your User Content, and any our findings or results
        from our Services with Chia.<br></br>
        <br></br>
        (g) We may use usage patterns, trends, elements, and other statistical data derived from your
        User Content and use of the Software or Services for purposes of providing, operating,
        maintaining, or improving the Software or Services.   This may include  aggregating,
        pooling, and maintaining certain elements of your User Content along with other data
        attributes provided by other users (the “Aggregated Data Pool”) for the purpose of
        determining fraud, reputation, and identity as it relates to the KYC Services.   You
        acknowledge that once such aggregated data is part of the Aggregated Data Pool, it is
        neither identifiable as coming from you nor separately removable.  All such aggregated
        data is solely used for purposes of determining fraud, reputation, and identity as it relates
        to KYC Services.  You grant us a perpetual, non-terminable, royalty free license to use
        such elements of your User Content as part of the Aggregated Data Pool.<br></br>
        <br></br>
        <b>8. <u>Feedback.</u></b><br></br> 
        <br></br>
        (a) If you send or transmit any communications or materials to us by mail, e-mail, telephone,
        or otherwise (the “Feedback”), suggesting or recommending changes to our Software or
        Services, including, without limitation, new features or functionality relating thereto, any
        comments, questions, suggestions, or the like, all such Feedback is, and will be treated as,
        non-confidential and non-proprietary. This means that you give up any claim that the use
        of such Feedback by us, violates any of your rights including, without limitation, moral
        rights, privacy rights, proprietary or other property rights, rights of publicity, rights to
        credit for material or ideas, or any other right, including the right to approve the way we
        use such Feedback.<br></br>
        <br></br>
        (b) You hereby assign all right, title, and interest in, and we are free to use, without any
        attribution or compensation to you, any ideas, know-how, concepts, techniques, or other
        intellectual property rights contained in the Feedback, whether or not patentable, for any
        purpose whatsoever, including but not limited to, developing, manufacturing, having
        manufactured,   licensing,   marketing,   and   selling,   directly   or   indirectly,   products   and
        services using such Feedback. You agree and understand that we are not obligated to use,
        display, reproduce, or distribute any such ideas, know-how, concepts, or techniques
        contained   in   the   Feedback,   and   you   have   no   right   to   compel   such   use,   display,
        reproduction, or distribution.<br></br>
        <br></br>
        <b>9. <u>Services Availability & Support.</u></b><br></br>
        <br></br> 
        (a) We will make the Services available to you consistent with the manner in which we make
        the Services generally available to other users of the Services. You acknowledge and agree
        that the form and nature of the Services, or any individual Service, which we currently
        provide may change from time-to-time without prior notice to you. By continuing to use
        the Services after such changes have been made, you are indicating your acceptance of
        such changes that we may make at any time in its sole discretion.<br></br>
        <br></br>
        (b) We do not make any representations or guarantees regarding uptime or availability of the
        Services.  We do not guarantee that the Services will meet any particular service level, or
        that any transactions or activities carried out through the Services will be carried out in
        any specific timeframe. The Services may be unavailable at certain times, including during
        any unanticipated or unscheduled downtime or unavailability of all or any portion of the
        Services as a result of system failures or force majeure events described in this Agreement.
        We   will   use   commercially   reasonable   efforts,   circumstances   permitting,   to   provide
        information regarding any such Services interruptions and the restoration of use of, and
        access to, the Services following any Services interruption, including by information
        posted on the website or sent to your registered e-mail address.<br></br>
        <br></br>
        (c) We may temporarily suspend your access to any portion or all of the Services if we
        reasonably determines that: (a) there is a threat or attack on the Services (including a
        denial of service attack) or other event that may create a risk to the Services, you or any
        other Services user; (b) your use of the Services or your User Content disrupts or poses a
        security risk to the Services or any other Services user, may harm our systems or any other
        user, or may subject us or any third party to liability; (c) you are using the Services for
        fraudulent or illegal activities; (d) subject to applicable law, you have ceased to continue
        your business in the ordinary course, made an assignment for the benefit of creditors or
        similar   disposition   of   your   assets,   or   become   the   subject   of   any   bankruptcy,
        reorganization,   liquidation,   dissolution   or   similar   proceeding;   (e)   you   are   using   the
        Software or Services in breach of the Agreement; or (f) you are in default of any payment
        obligations   to   us   (collectively,   "Services   Suspensions").   We   will   use   commercially
        reasonable efforts, circumstances permitting, to provide written notice of any Services
        Suspension to you (including via notices posted on the Website or sent to your registered
        e-mail address) and to provide updates regarding resumption (if any) of the Services
        following any Services Suspension. We will have no liability for any damage, liabilities,
        losses (including, without limitation, any loss of data or profits) or any other consequences
        that you or any third party may incur as a result of any Services Suspension.<br></br>
        <br></br>
        <b>10. <u>Liability Waivers;  Limitation of Liability</u></b><br></br>
        <br></br>
        (a) Disclaimer of Warranties   . YOUR USE OF THE SOFTWARE AND SERVICES IS
        ENTIRELY AT YOUR OWN RISK AND THE SERVICES ARE PROVIDED “AS IS”
        AND   WITHOUT   WARRANTIES   OR   CONDITIONS,   STATUTORY   OR
        OTHERWISE, OF ANY KIND.   TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE   LAW.   WE,   AND   OUR   THIRD   PARTY   SERVICE   PROVIDERS,
        LICENSORS, OR DISTRIBUTORS DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, INCLUDING ANY WARRANTY THAT THE SERVICES ARE FIT FOR A
        PARTICULAR   PURPOSE,   TITLE,   MERCHANTABILITY,   DATA   LOSS,   NON-
        INFRINGEMENT   OF   ANY   INTELLECTUAL   PROPERTY   RIGHTS,   OR   THE
        ACCURACY, RELIABILITY, QUALITY, OR CONTENT IN OR LINKED TO THE
        SOFTWARE OR SERVICES. WE AND OUR THIRD PARTY SERVICE PROVIDERS
        DO NOT WARRANT THAT THE SERVICES ARE SECURE, FREE FROM BUGS,
        VIRUSES, INTERRUPTION, ERRORS, THEFT, OR DESTRUCTION. <br></br>
        <br></br>
        (b) YOU   ARE   RESPONSIBLE   FOR   IMPLEMENTING   SUFFICIENT   PROCEDURES
        AND CHECKPOINTS TO SATISFY YOUR PARTICULAR REQUIREMENTS FOR
        ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT,
        AND FOR MAINTAINING A MEANS EXTERNAL TO OUR WEBSITE FOR ANY
        RECONSTRUCTION   OF   ANY   LOST   DATA.   TO   THE   FULLEST   EXTENT
        PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
        CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
        OTHER   TECHNOLOGICALLY   HARMFUL   MATERIAL   THAT   MAY   INFECT
        YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
        PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SOFTWARE OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SOFTWARE OR TO YOUR
        DOWNLOADING OF ANY MATERIAL POSTED ON IT.<br></br>
        <br></br>
        (c) WE   AND   OUR   THIRD   PARTY   SERVICE   PROVIDERS   DISCLAIM   ANY
        REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES
        WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS,
        OR   LAWS   OR   REGULATIONS.   THE   FOREGOING   DOES   NOT   AFFECT   ANY
        WARRANTIES   THAT   CANNOT   BE   EXCLUDED   OR   LIMITED   UNDER
        APPLICABLE LAW.<br></br>
        <br></br>
        (d) YOU WILL NOT HOLD US NOR CHIA LIABLE IF WE ARE UNABLE TO VERIFY
        YOUR IDENTITY WITH THE USER CONTENT THAT YOU PROVIDE TO US.
        YOU FURTHER AGREE THAT NEITHER US NOR CHIA WILL BE LIABLE TO
        YOU FOR ANY DAMAGES IF YOU ARE UNABLE TO PARTICIPATE IN CHIA’S
        TRADING PLATFORM AS A RESULT OF OUR KYC SERVICES.<br></br>
        <br></br>
        (e)LIMITATION OF LIABILITY   . YOU AND ANY OTHERS FOR WHOM SERVICES
        ARE PROVIDED MAY NOT RECOVER FROM US OR CHIA, IN CONTRACT OR
        TORT,   UNDER   STATUTE   OR   OTHERWISE,   ANY   CONSEQUENTIAL,
        INCIDENTAL, INDIRECT, PUNITIVE, OR SPECIAL DAMAGES IN CONNECTION
        WITH   CLAIMS   ARISING   OUT   OF   THIS   AGREEMENT   OR   OTHER   WISE
        RELATING TO THE SOFTWARE OR SERVICES, INCLUDING ANY AMOUNT FOR
        LOSS OF PROFIT, DATA OR GOODWILL, WHETHER OR NOT THE LIKELIHOOD
        OF SUCH LOSS OR DAMAGE WAS CONTEMPLATED.  IN ADDITION, YOU AND
        ANY OTHERS FOR WHOM SERVICES ARE PROVIDED MAY NOT RECOVER
        FROM   US   OR   CHIA,   IN   CONTRACT   OR   TORT,   UNDER   STATUTE   OR
        OTHERWISE, AGGREGATE DAMAGES IN EXCESS OF THE FEES ACTUALLY
        PAID   FOR   THE   SERVICES   THAT   DIRECTLY   CAUSED   THE   LOSS   IN
        CONNECTION   WITH   CLAIMS   ARISING   OUT   OF   THIS   AGREEMENT   OR
        OTHERWISE RELATING TO THE SERVICES.<br></br>
        <br></br>
        (f)THIS LIMITATION OF REMEDIES IN SECTION 10 IS A PART OF THE BARGAIN
        BETWEEN YOU AND US. NO ORAL OR WRITTEN INFORMATION OR ADVICE
        GIVEN   BY   US   OR   ANY   PERSON   ON   BEHALF   OF   US   SHALL   CREATE   A
        WARRANTY OR CONDITION, OR IN ANY WAY CHANGE THIS EXCLUSION OF
        WARRANTY.   HOWEVER. NOTHING IN THIS AGREEMENT SHALL EXCLUDE
        OR LIMIT OUR WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE
        LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.<br></br>
        <br></br>
        <b>11.  <u>Indemnity.</u></b>  <br></br>
        <br></br>
        (a)You hereby agree to indemnify, defend and hold harmless us, Chia, and our respective
        officers, directors, shareholders, employees, and agents (each an “Indemnified Party”),
        from and against any and all liability and costs (including, without limitation, attorneys’
        fees and costs) incurred by the Indemnified Party(s) in connection with any actual or
        alleged claim arising out of: (a) your use of the Software or Services; (b) any breach or
        alleged breach by you of the Agreement or other binding legal obligations; (c) any User
        Content provided by you; (d) any breach or alleged breach by you of a third party’s
        rights, including, without limitation, any intellectual property, privacy or publicity rights;
        (e) any damage caused by or alleged to have been caused by you to the Software or
        Services; or (f) any actual or alleged violation or non-compliance by you with any
        applicable law, rule or regulation. <br></br>
        <br></br>
        (b) Counsel that you select for the defense or settlement of a claim must be consented to
        by the Indemnified Party(s) prior to counsel being engaged to represent you and the
        Indemnified   Party(s).   You   and   your   counsel   will   cooperate   as   fully   as   reasonably
        required, and provide such information as reasonably requested, by the Indemnified
        Party(s) in the defense or settlement of any claim. We and/or the other Indemnified
        Party(s)   reserves   the   right,   at   its   own   expense,   to   assume   the   exclusive   defense   or
        settlement, and control of any matter otherwise subject to indemnification by you. You
        shall not in any event consent to any judgment, settlement, attachment, or lien, or any
        other act adverse to the interests of us or any other Indemnified Party(s) without the prior
        written consent of us and/or the other Indemnified Party(s). <br></br>
        <br></br>
        <b>12.<u>Termination.</u></b> <br></br>
        <br></br>
        (a) This Agreement shall terminate upon the completion of the Services. We may terminate
        or suspend your access to all or part of the Software or Services if you violate any
        provision in this Agreement or fail to pay the applicable Fees. The Agreement shall
        terminate   upon   your   deletion   of   your   Account.   In   addition,   we   may   terminate   the
        Agreement  or  any particular  Service  immediately upon written notice  to you if  we
        reasonably determine that we can no longer provide the Services in accordance with
        applicable law.<br></br>
        <br></br>
        (b) Notwithstanding termination, any of your obligations under the Agreement which by their
        nature are intended to survive the termination of your use of the Services shall continue to
        apply to you after you cease to use the Services.<br></br>
        <br></br>
        <b>13. <u>Third Party Web Sites.</u></b><br></br>
        <br></br>
        (a) The Software and Services may provide links to other web sites that are not owned or
        operated by us (“Third-Party Web Sites”). We provide these links to you as a convenience
        only, and we do not verify, make any representations concerning, or take responsibility
        for, such Third-Party Web Sites, or the products or services offered through such Third-
        Party Web Sites, including, without  limitation, the  truthfulness, accuracy, quality, or
        completeness of the content of, or activities conducted on, such Third-Party Web Sites.
        You should use your own independent judgment before accessing and using such Third-
        Party Web Sites, or products or services offered through such Third-Party Web Sites. <br></br>
        <br></br>
        (b) The Agreement and the Privacy Policy do not apply to such Third-Party Web Sites, and
        you should review such Third-Party Web Sites’ privacy policies, terms and conditions
        and business practices as they may be different to ours,  and it is your sole responsibility
        to comply with such terms. Your dealings and communications with any third party in
        connection with the Third-Party Web Sites are solely between you and such third party. <br></br>
        <br></br>
        (c) Any   reference   on   the   Software,   or   through   the   Services,   to   any   product,   process,
        publication or service of any third party, by trade name, domain name, trademark, trade
        identity, service mark, logo, manufacturer or otherwise, does not constitute or imply our
        endorsement or recommendation thereof, and your use of any Third-Party Web Sites and
        third-party product, process, publication, or service is entirely at your own risk.<br></br>
        <br></br>
        <b>14. <u>General Terms and Conditions.</u></b><br></br>
        <br></br>
        (a) <u>Subcontract and Processing</u>   . We will provide the Services to you as an independent
        contractor and not as your employee, agent, partner, or joint venture. Neither you nor we
        have any right, power, or authority to bind the other. We may provide your User Content
        to third-party service providers who may collect, use, transfer, store, or otherwise process
        it in various jurisdictions in which they operate in order to facilitate performance of the
        Services,   to   comply   with   regulatory   requirements,   to   provide   administrative   support
        services, or for quality and risk management purposes.<br></br>
        <br></br>
        (b) <u>Governing Law.</u> This Agreement, and any non-contractual matters or obligations arising
        out of this Agreement or the Services shall be governed by, and construed in accordance
        with, the laws of California.<br></br>
        <br></br>
        (c) <u>Severability.</u>  If any provision of the Agreement (in whole or part) is held to be illegal,
        invalid, or otherwise unenforceable, the other provisions shall remain in full force and
        effect.<br></br>
        <br></br>
        (d) <u>Notice.</u>      Any notice or other communications by us relating to the Software or Services
        may be made by letter, e-mail or posting on the Website, and you hereby consent to
        receive notices and other communications in electronic form to the extent permitted by
        applicable law.<br></br>
        <br></br>
        (e) <u>Assignment.</u>   We may assign or transfer our rights, or delegate any performance, under the
        Agreement to a third party in its sole discretion. You may not assign or otherwise transfer
        your rights, or delegate your performance, under the Agreement to any third party without
        in each and every case, our express prior written consent.<br></br>
        <br></br>
        (f) <u>Force Majeure.</u>  We will not be liable for failing or delaying performance of its obligations
        resulting from any condition beyond its reasonable control, including but not limited to,
        governmental action, acts of the common enemy, earthquake, fire, flood or other acts of
        God, the elements, epidemics,  labor  conditions, power failures, Internet disturbances, or
        other force majeure event.<br></br> 
        <br></br>
        (g) <u>Arbitration.</u>     YOU   AND   US   ARE   AGREEING   TO   GIVE   UP   ANY   RIGHTS   TO
        LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A
        CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM.
        OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY
        ALSO   BE   UNAVAILABLE   OR   MAY   BE   LIMITED   IN  ARBITRATION.     ANY
        CLAIM, DISPUTE, OR CONTROVERSY (WHETHER IN CONTRACT, TORT, OR
        OTHERWISE,   WHETHER   PRE-   EXISTING,   PRESENT,   OR   FUTURE,   AND
        INCLUDING   STATUTORY,   CONSUMER   PROTECTION,   COMMON   LAW,
        INTENTIONAL TORT, INJUNCTIVE, AND EQUITABLE CLAIMS) BETWEEN YOU
        AND US ARISING FROM OR RELATING IN ANY WAY TO THE SERVICES WILL
        BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.<br></br>
        <br></br>
        The arbitration will be administered by the American Arbitration Association (“AAA”)in
        accordance with the Commercial Arbitration Rules and Mediation Procedures (the “AAA
        Rules”) then in effect, except as modified by this subsection (g). The AAA Rules are
        available at adr.org or by calling the AAA at 1-800-778-7879. The Federal Arbitration Act
        will govern the interpretation and enforcement of this subsection (g).<br></br>
        <br></br>
        The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability
        and/or   enforceability   of   this   arbitration   provision,   including   any   unconscious   ability
        challenge or any other challenge that the arbitration provision or the Agreement is void,
        voidable, or otherwise invalid. The arbitrator will be empowered to grant whatever relief
        would be available in court under law or in equity. Any award of the arbitrator(s) will be
        final and binding on each of the parties and may be entered as a judgment in any court of
        competent jurisdiction. Any face-to-face arbitration proceedings will take place in Santa
        Clara, California.<br></br>
        <br></br>
        You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU NOR
        US WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST
        OTHER   CUSTOMERS   IN   COURT   OR   IN   ARBITRATION   OR   OTHERWISE
        PARTICIPATE   IN   ANY   CLAIM   AS   A   CLASS   REPRESENTATIVE,   CLASS
        MEMBER,   OR   IN   A   PRIVATE   ATTORNEY   GENERAL   CAPACITY.   The   arbitral
        tribunal may not consolidate more than one person’s claims, and may not otherwise
        preside over any form of a representative or class proceeding. The arbitral tribunal has no
        power to consider the enforceability of this class arbitration waiver and any challenge to
        the class arbitration waiver may only be raised in a court of competent jurisdiction.<br></br> 
        <br></br>
        On clicking the Agree button below you acknowledge that you have read and understood
        the terms and conditions of this Agreement, and that you agree to the terms
        and conditions set forth herein.<br></br>
        <br></br>
    </p>
    </>
  );
};

export default Disclosures;