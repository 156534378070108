import React, { useState, useEffect } from "react";
import { Container, Navbar, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const Header = (props) => {

  const navigate = useNavigate();

  const [logoBase64Text, setLogoBase64Text] = useState('');

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    if(!props.isAdmin){//load only for customers
      const script = document.createElement('script');
      script.id = "//js.hs-scripts.com/178283.js";
      script.src = "//js.hs-scripts.com/178283.js";
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    } else {
      //Remove chatbot
      setTimeout(() => {
        document.getElementById("hubspot-messages-iframe-container")?.remove();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    console.log(window.location.hostname);
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    //axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    axios.post(serverConfig.api_base_url + "get_favicon_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_favicon_image response', response);
      if(response.data.status === 200) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = response.data.faviconBase64;
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
	},[]);

  return (
    <header>
      <Navbar expand="md">
        <Container fluid className="justify-content-between position-relative">
          <Navbar.Brand onClick={() => navigate("/db")} role="button"><img src={logoBase64Text} alt="Logo" className="img-fluid" /></Navbar.Brand>
          {props.isRevoking && <div><img src={require('./../assets/images/revoking-text.gif')} alt="Revoking" /></div>}
          {props.isMintingInProgress && <div><img src={require('./../assets/images/minting-loader.png')} alt="Minting" /></div>}
          {props.isIssuing && <div><img src={require('./../assets/images/issuing-loader.png')} alt="Issuing" /></div>}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav">
              <li><Link to="/profile">Profile</Link></li>
              <li><Button variant="plain" onClick={handleLogout}>Logout</Button></li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;