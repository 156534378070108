import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Header from "../components/Header.jsx";
import axios from "axios";
import UserService from '../services/UserService';

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';

import CardForm from "../components/methods/CardForm";
import Xch from "../components/methods/Xch";
import FpxBankForm from "../components/methods/FpxBankForm";
import IbanForm from "../components/methods/IbanForm";
import IdealBankForm from "../components/methods/IdealBankForm";
import PaymentRequestForm from "../components/methods/PaymentRequestForm";
import SplitForm from "../components/methods/SplitForm";
import AfterpayClearpayMessage from "../components/methods/AfterpayClearpayMessage";
import CheckoutForm from "./CheckoutForm";
import CreditCard from "../components/methods/CreditCard";

const serverConfig = require("../config/server.js");

//const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');//test
//const stripePromise = loadStripe("pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3");//test Nevil
//const stripePromise = loadStripe("pk_test_51ObPOnSHomqqZI0rj1EtaJZp7CxHQYI25mmOm3n6WHztJa2pZCUIWdKndEx3KGGmwKP8FxVdDNx9Dh7azuZBDpkD004YNpyIpH");//test Ranjith
const stripePromise = loadStripe("pk_live_51NJPAKEvm8kAQwFA7PbnXBlYUQ3OTKVOMZ2KSJxot6BpO0hCjxQ2acFVdJYB8nNGlQPw9sOq8P6GXjXp7j4l2VYr00Bl0uSSdB");

const demos = [
  /* {
    methodName: "card_element",
    label: "Card Element",
    component: CardForm
  }, */
  {
    methodName: "xch",
    label: "XCH",
    icon: "chia-logo.png",
    component: Xch
  },
  {
    methodName: "credit_card_elements",
    label: "Credit Card",
    icon: "credit-card-icon.png",
    component: CreditCard
  },
  /* {
    methodName: "split_card_elements",
    label: "Credit Card",
    icon: "credit-card-icon.png",
    component: SplitForm
  },
  {
    methodName: "payment_request_button_element",
    label: "Payment Request Button Element",
    component: PaymentRequestForm
  },
  {
    methodName: "ideal_bank_element",
    label: "Ideal Bank Element",
    icon: "ach-icon.png",
    component: IdealBankForm
  },
  {
    methodName: "iban_element",
    label: "Iban Element",
    icon: "ach-icon.png",
    component: IbanForm
  },
  {
    methodName: "fpx_bank_element",
    label: "Fpx Bank Element",
    icon: "ach-icon.png",
    component: FpxBankForm
  },
  {
    methodName: "afterpay_clearpay_message",
    label: "Afterpay Clearpay Message Element",
    component: AfterpayClearpayMessage
  } */
];

const Payment = () => {

  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [currency, setCurrency] = useState(null);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    //axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const[method, setMethod] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const handleSelectMethod = (mName) => {
    setMethod(mName);
  }

  useEffect(() => {
    if(currency !== null){
      fetch(serverConfig.fapi_base_url + "create_payment_intent", {
        method: "POST",
        body: JSON.stringify({amount:99, currency:currency}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }
  }, [currency]);

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  useEffect(() => {
    if(token !== null){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('check_user_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
            //application_list_read - is admin / banker
            axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
              console.log('check_user_privilege response', response);
              if(response.data.status === 200) {
                if(response.data.is_privileged === 1){
                  navigate("/applications");
                } else if(response.data.is_privileged === 0) {
                  handleLogout();
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "payment_status_get",{token: token})
      .then(response=>{
        console.log('payment_status_get', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            if(response.data.items.isCompleted === 1){
              navigate('/db');
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      
      axios.post(serverConfig.api_base_url + "currency_get",{token: token})
      .then(response=>{
        console.log('currency_get', response);
        if(response.data.status === 200) {
          setCurrency(response.data.currency);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handlePaymentSet = (subscriptionID) => {
    console.log(currency, subscriptionID);
    axios.post(serverConfig.api_base_url + "payment_status_set",{token: token, currency: currency, subscriptionID: subscriptionID})
    .then(response=>{
      console.log('payment_status_set', response);
      if(response.data.status === 200) {
        navigate('/db');
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <div className="body-wrap bg-color8">
      <Header />
      <main>
        <Container fluid className='page-title title-center mb-4'>
          <h1>Choose a payment method</h1>
        </Container>
        <Container fluid>
          <Row className='method-options mb-3'>
            {demos.map((item, index) => {
              return(
                <Col xs="4" key={index} className="mb-3">
                  <div className="form-check">
                    <input name="method" type="radio" id={item.methodName} className="form-check-input" onChange={() => handleSelectMethod(item.methodName)} />
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span></span>
                      <img src={require(`./../assets/images/${item.icon}`)} />
                    </div>
                    <label htmlFor={item.methodName} className="form-check-label">{item.label}</label>
                  </div>
                </Col>
              )
            })}
          </Row>

          {method && <h5 className='mb-3'>Payment info</h5>}
          
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              {/* <CreditCard /> */}
              {demos.map(({ methodName, component: Component }, index) => {
                return (
                  <div key={index}>
                    {(() => {
                      switch (method) {
                        case methodName:
                          return (
                            <Component client_secret={clientSecret} handlePaymentSet={handlePaymentSet} />
                          );
                        default:
                          return (
                            <div></div>
                          );
                        }
                    })()}
                  </div>
                )
              })}
            </Elements>
          )}
        </Container>
      </main>
    </div>
  );
};

export default Payment;