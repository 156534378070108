import React, { useEffect, useState } from 'react';
import { useWalletConnectClient } from './walletconnect/contexts/ClientContext';
import Blockchain, { SAction, SFullWidthContainer } from './walletconnect/components/Blockchain';
import Toggle from './walletconnect/components/Toggle';
import { useChainData } from './walletconnect/contexts/ChainDataContext';
import { setLocaleStorageTestnetFlag } from './walletconnect/helpers';
import { useJsonRpc } from './walletconnect/contexts/JsonRpcContext';
import { AccountAction } from './types';
import { DEFAULT_TEST_CHAINS, DEFAULT_MAIN_CHAINS, DEFAULT_CHIA_METHODS } from './walletconnect/constants';
import {
    SButtonContainer,
    SConnectButton,
    SContent,
    SLanding,
    SLayout,
    SToggleContainer,
} from './walletconnect/app';
import Column from './walletconnect/components/Column';
import Header from './walletconnect/components/Header'
import { Button, Modal } from 'react-bootstrap';
import PairingModal from './PairingModal';
import RequestModal from './RequestModal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserService from 'services/UserService';
import styled from 'styled-components';
import server from 'config/server';

const serverConfig = require("../config/server.js");

const WalletConnect = (props : {isChiaUser: boolean, setPaymentCompleted: any, paymentCompleted: any, setUserWalletAddress?: any, setUserIdentityVerified?: any, proofsForVC: string, page: string, setWalletConnected?: any, userWalletAddress?:any, userIdentityVerified?:any, walletConnected?:any, setFingerprint?: any, credential?: any, addedVCProofs: any, setAddedVCProofs: any, connection: any, setConnection: any}) => {

    const {isChiaUser, setPaymentCompleted, paymentCompleted, userWalletAddress, setUserWalletAddress, setUserIdentityVerified, proofsForVC, page, setWalletConnected, userIdentityVerified, walletConnected, setFingerprint, credential, addedVCProofs, setAddedVCProofs, connection, setConnection} = props;
    //const openPairingModal = () => console.log("no op pairing modal")
    const [modal, setModal] = useState("");
    const [signAddress, setSignAddress] = useState('');
    const [publicKey, setPublicKey] = useState('');
    const [token, setToken] = useState<string | null>(null);
    const [message, setMessage] = useState('This is a testsign in message by address');
    const [signature, setSignature] = useState('');
    const [puzzleHash, setPuzzleHash] = useState('');
    const [show, setShow] = useState(false);
    const [issuingInProgress, setIssuingInProgress] = useState(false);
    const [parentCoinInfo, setParentCoinInfo] = useState('');
    const [masterPublicKey, setMasterPublicKey] = useState('');
    const [addressToVerify, setAddressToVerify] = useState('');
    const [stepNumber, setStepNumber] = useState('1');
    const [name, setName] = useState('');
    //const [addedVCProofs, setAddedVCProofs] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [paymentButtonText, setPaymentButtonText] = useState('Submit Payment');

    const openPairingModal = () => setModal("pairing");
    const openRequestModal = () => setModal("request");
    const closeModal = () => { setModal(""); }

    const {
        accounts,
        chains,
        client,
        connect,
        isInitializing,
        pairings,
        setChains,
        balances,
        session
    } = useWalletConnectClient();
   
    const { chiaRpc, isRpcRequestPending, rpcResult } = useJsonRpc();

    const { isTestnet, setIsTestnet } = useJsonRpc();

    const { chainData } = useChainData();

    const navigate = useNavigate();

    const invokeOnConnect = () => {
      console.log('invokeOnConnect called')
       if (typeof client !== "undefined") {
        onConnect();  
       } else {
        setTimeout(() => {
          invokeOnConnect();
        }, 3000);
       }
    };

      const handleLogout = () => {
        UserService.doLogout();
        sessionStorage.clear();
        localStorage.clear();
      }

    const mtkWalletAddressGet = () => {
      axios.post(serverConfig.api_base_url + "mtk_wallet_address_get",
      {
        "token" : token
      }).then(response=> {
        console.log("mtk_wallet_address_get response", response.data);
        if(response.data.status === 200) {
          if(response.data.mtkWalletAddress === null) {
            getNewAddress();
          }
          else {
            setWalletAddress(response.data.mtkWalletAddress);
            addressDecode(response.data.mtkWalletAddress);
          }
        }
      }).catch(err=> {
        console.log(err);
      })
    }


    const paymentStatusSet = () => {
        axios.post(serverConfig.api_base_url + "payment_status_set",{token: token, currency:"xch"})
    .then(response=>{
      console.log('payment_status_set', response);
      if(response.data.status === 200) {
        navigate('/db');
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    }

    const renderModal = () => {
        switch (modal) {
          case "pairing":
            if (typeof client === "undefined") {
              throw new Error("WalletConnect is not initialized");
            }
            return <PairingModal pairings={pairings} connect={connect} />;
          case "request":
            return (
              <RequestModal pending={isRpcRequestPending} result={rpcResult} setSignAddress={setSignAddress} setPublicKey={setPublicKey} setParentCoinInfo={setParentCoinInfo} setSignature={setSignature} setMasterPublicKey={setMasterPublicKey} setUserWalletAddress={setUserWalletAddress} setStepNumber={setStepNumber} name={name} modal={modal} closeModal={closeModal} setAddedVCProofs={setAddedVCProofs} setWalletConnected={setWalletConnected}/>
            );
        //   case "ping":
        //     return <PingModal pending={isRpcRequestPending} result={rpcResult} />;
          default:
            return null;
        }
      };

    let interval: any;

    const getTransaction = (parentCoinId: any) : any => {
        axios.post(serverConfig.api_base_url + "get_coin_records_by_puzzle_hash", {
            "puzzle_hash" : puzzleHash,
            "chain" : "fn.mainnet"
        }).then(response => {
            console.log(response?.data?.coin_records);
            console.log(parentCoinId, 'coiniD');
            console.log(response?.data?.coin_records[0]?.coin?.parent_coin_info, 'top_element');
            const coinArray = response?.data?.coin_records;
            coinArray.map((coinRecord : any) => {

              if(coinRecord?.coin?.parent_coin_info === parentCoinId) {
                clearInterval(interval);
                paymentStatusSet();
                setIsLoading(false);
                setPaymentButtonText('Submit Payment');
            }

            })
        }).then(err => {
            console.log(err);
        })
        setPaymentCompleted(true);
    }

    const generateAddress = (masterPk: any, address: any) => {
        axios.post(serverConfig.fapi_base_url + "address_verify" , {
            "master_pk": masterPk,
            "address": address
        }).then(response=> {
            console.log("generate address response" , response)
            if(response.data.status === 200) {
                //alert('User Identity Verified');
                setUserIdentityVerified(true);
            }
        }).catch(err=> {
            console.log(err);
        })
    };

    const addressDecode = (addressOfWallet: any) => {
      axios.post(serverConfig.fapi_base_url + "bech32_decode", {
        "address" : addressOfWallet
      }).then(response=> {

        console.log("bech32_response", response.data);
        if(response.data.status === 200) {
          console.log("bech32_puzzle_hash" , response.data.result);
          setPuzzleHash(response.data.result);
        }
        else if(response.data.status === 400) {
          alert("Address is Missing");
        }
      }).catch(err => {
        console.log(err);
      })
    }

    const getNewAddress = () => {
      axios.post(serverConfig.api_base_url + "get_next_address", {
        "wallet_id":1,
        "new_address":true,
        "chain": "mainnet"
      }).then(response=> {
        console.log("get_new_address", response.data);
        if(response.data.success == true) {
          console.log('Address', response.data.address);
          const address = response.data.address;
          setWalletAddress(response.data.address);

          axios.post(serverConfig.api_base_url + "mtk_wallet_address_set",
            {
              "token" : token,
              "mtkWalletAddress" : response.data.address
            }).then(response=> {
              console.log("mtk_wallet_address_set response", response.data);
              if(response.data.status === 200) {

              // decoding address and generating puzzHash
              addressDecode(address);               
              }
            }).catch(err=> {
              console.log(err);
            })

        }
      }).catch(err=> {
        console.log(err);
      })
    }


    const getChiaPaymentActions = () : any=> {
        const account = accounts[0];
        const [namespace, reference, fingerprint] = account.split(":");
        console.log(account, 'ACCOUNT');
        console.log(fingerprint, 'FINGERPRINT');
        
        const onSendTransaction = async (chainId: string, address: string) => {
          setIsLoading(true);
          setPaymentButtonText('Processing...')
            openRequestModal();
            await chiaRpc.testSendTransaction(chainId, address, walletAddress);         
          };

        return [
            {
                label: paymentButtonText,
                method: DEFAULT_CHIA_METHODS.CHIA_SEND_TRANSACTION,
                callback: onSendTransaction,
            },
        ]
    }

    const getAddress = (): any => {

      const account = accounts[0];
      const onNewAddress = async (chainId: string, address: string) => {
        openRequestModal();
        setName('Get Next Address');
        await chiaRpc.testNewAddress(chainId, address);
      };

      return [
        {
            label: 'Get Next Address',
            method: DEFAULT_CHIA_METHODS.CHIA_NEW_ADDRESS,
            callback: onNewAddress,
        },
      ]

    }

    const getCompleted = (): any => {

      const account = accounts[0];

      return [
        {
        },
      ]

    }

    const getMasterPublicKey = () => {

      const account = accounts[0];
      const getPublicKey = async (chainId: string, address: string) => {
        openRequestModal();
        setName('Get Master Public Key');
    await chiaRpc.getPublicKey(chainId, address);
    }

    return [
      {
        label: 'Get Master Public Key',
        method: DEFAULT_CHIA_METHODS.CHIA_GET_PUBLIC_KEY,
        callback: getPublicKey
    },
    ]
   
    }

    const signMessageByAddress = () => {
      const account = accounts[0];
       const [namespace, reference, fingerprint] = account.split(":");
        console.log(account, 'ACCOUNT');
        console.log(fingerprint, 'FINGERPRINT');
        setFingerprint(fingerprint);
      const onSignMessageByAddress = async (chainId: string,fingerprints:any, messages:any, address: string) => {
        if(signAddress && masterPublicKey) {
            openRequestModal();
            setName('Sign Message By Address');
            await chiaRpc.signMessageByAddress(chainId, fingerprint, message, signAddress);
        }
      };
      return [
        {
          label: 'Sign Message By Address',
          method: DEFAULT_CHIA_METHODS.CHIA_SIGN_MESSAGE_BY_ADDRESS,
          callback: onSignMessageByAddress,
      },
      ]
    }

    const addVCProofs = () => {
      const account = accounts[0];
      const addVcProofs = async (chainId: string, address: string, proofs: string) => {
        openRequestModal();
        setName('Add VC Proofs');
        await chiaRpc.addVCProofs(chainId, address, proofsForVC);
      };

      return [
        {
          label: 'Add Proofs to VC',
          method: DEFAULT_CHIA_METHODS.CHIA_ADD_VC_PROOFS,
          callback: addVcProofs
        },
      ]

    }


    const getChiaActions = (): any=> {

        const account = accounts[0];
        const [namespace, reference, fingerprint] = account.split(":");
        console.log(account, 'ACCOUNT');
        console.log(fingerprint, 'FINGERPRINT');
        
        // const onSendTransaction = async (chainId: string, address: string) => {
        //     openRequestModal();
        //     await chiaRpc.testSendTransaction(chainId, address);         
        //   };

            const onNewAddress = async (chainId: string, address: string) => {
                openRequestModal();
            await chiaRpc.testNewAddress(chainId, address);
            };

            const getPublicKey = async (chainId: string, address: string) => {
                openRequestModal();
            await chiaRpc.getPublicKey(chainId, address);
            };

          const onSignMessageByAddress = async (chainId: string,fingerprints:any, messages:any, address: string) => {
            if(signAddress && masterPublicKey) {
                openRequestModal();
                await chiaRpc.signMessageByAddress(chainId, fingerprint, message, signAddress);
            }
          };

          const addVcProofs = async (chainId: string, address: string, proofs: string) => {
            openRequestModal();
            await chiaRpc.addVCProofs(chainId, address, '{"criminal_charges":"false","id":"MonteiroKA5120170002039India1695208432293","pep":"false","terrorist":"false","us_citizen":"false"}');
        };

          return [
            {
                label: 'Get Next Address',
                method: DEFAULT_CHIA_METHODS.CHIA_NEW_ADDRESS,
                callback: onNewAddress,
            },
            {
                label: 'Get Master Public Key',
                method: DEFAULT_CHIA_METHODS.CHIA_GET_PUBLIC_KEY,
                callback: getPublicKey
            },
            {
                label: 'Sign Message By Address',
                method: DEFAULT_CHIA_METHODS.CHIA_SIGN_MESSAGE_BY_ADDRESS,
                callback: onSignMessageByAddress,
            },
            {
              label: 'Add Proofs to VC',
              method: DEFAULT_CHIA_METHODS.CHIA_ADD_VC_PROOFS,
              callback: addVcProofs
            },
          ]    
    }


    const getBlockchainActions = (chainId: string) => {
        const [namespace] = chainId.split(":");
        switch (namespace) {
            case "chia":
                if(page === 'dashboard' && credential?.length > 0)
                {
                  if(userWalletAddress === '' && masterPublicKey === '' && userIdentityVerified === false) {
                    return getAddress();
                  }
                  else if( userWalletAddress !== '' && masterPublicKey === '' && userIdentityVerified === false ) {
                    return getMasterPublicKey();
                  }
                  else if(userWalletAddress !== '' && masterPublicKey !== '' && userIdentityVerified === false) {
                    return signMessageByAddress();
                  }
                  else if(userWalletAddress !== '' && masterPublicKey !== '' && userIdentityVerified === true && addedVCProofs === false) {
                    return addVCProofs();
                  }
                  else {
                    return getCompleted();
                  }
                }   
                else if(page === 'payments')
                { 
                  return getChiaPaymentActions();
                }
                 else 
                {

                }
                 break;
            default:
                break;
        }
    }

  const verifyOwnership = () => {

        console.log(publicKey, signature, 'public_key and signature');
            axios.post(serverConfig.fapi_base_url + "ownership_verify", {
               "pubkey" : publicKey,
               "signature" : signature,
               "message" : message
            }).then(response=>{
                console.log(response, 'OWNERSHIP_VERIFY_RESPONSE');
                if(response.data.status === 200){
                  generateAddress(masterPublicKey, signAddress);
                }
            }).catch(err=>{
                console.log(err);
            })
  }

  

  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    //axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

   useEffect(() => {
        if(UserService.hasRole(["wallet-auth"])) {
          invokeOnConnect();
        }
    },[]);

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
      //getNewAddress();
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined) {      
      mtkWalletAddressGet();
    }
  }, [token]);

  useEffect(() => {
    if(publicKey && signature) {
        console.log(proofsForVC, 'proofs for vc');
        verifyOwnership();
    }
  }, [publicKey, signature]);

  useEffect(() => {
    if(parentCoinInfo) {
        interval = setInterval(function() {
            console.log(parentCoinInfo, 'coinId');
            getTransaction(parentCoinInfo);
          }, 3000);
    }
  },[parentCoinInfo])

   
    const onConnect = () => {
       handleChainSelectionClick('chia:mainnet');
        if (typeof client === "undefined") {
            throw new Error("WalletConnect is not initialized");
        }
        // Suggest existing pairings (if any).
        if (pairings?.length) {
            openPairingModal();
        } else {
            // If no existing pairings are available, trigger `WalletConnectClient.connect`.
            connect();
            setConnection(true);
        }
    };

    // Toggle between displaying testnet or mainnet chains as selection options.
    // const toggleTestnets = () => {
    //     const nextIsTestnetState = !isTestnet;
    //     setIsTestnet(nextIsTestnetState);
    //     setLocaleStorageTestnetFlag(nextIsTestnetState);
    // };

    const handleChainSelectionClick = (chainId: string) => {
        if (chains.includes(chainId)) {
            console.log(chainId, 'CHAIN _ ID');
            console.log(chains.filter((chain) => chain !== chainId), 'set_chains');
            setChains(chains.filter((chain) => chain !== chainId));
        } else {
            setChains([...chains, chainId]);
            console.log([...chains, chainId], 'set_chains');
        }
    };

    const renderContent = () => {
        const chainOptions = DEFAULT_TEST_CHAINS;

        return !accounts?.length && !Object.keys(balances).length ? (
              
          <>
               <div className='wallet-connect' onClick={onConnect}>

               <img src={require('./../assets/images/connect-icon.png')} alt="Connect icon" id="connect-img" className="me-1" />
                   <b>Connect</b>
               </div>
                <div className="txt-gray" id="not-connected">Wallet Not Connected</div>
          </>
        ) : (

            <>
            {accounts.length > 0 && (
                
            accounts?.map((account) => {
                console.log('inside blokchain')
                const [namespace, reference, address] = account.split(":");
                const chainId = `${namespace}:${reference}`;

                return (

                 page==="dashboard" ?
                  
                  <>
                   {credential?.length <=0 && <div className="connected-div">
                        <img src={require('./../assets/images/connected-icon.png')} alt="Connected icon" className="me-1" />
                        <b>Connected</b>
                      </div>
                    }

                  {!walletConnected && credential?.length > 0 && <> <div className='confirm-steps'>
                    <b>Complete Confirmation</b> 
                    <p className='steps'><p className='step-number'><b>Step {stepNumber}</b></p>/4</p>                  
                  </div>
                  {!addedVCProofs && 
                    <Blockchain
                        key={account}
                        active={true}
                        chainData={chainData}
                        address={address}
                        chainId={chainId}
                        balances={balances}
                        actions={getBlockchainActions(chainId)}
                        show={false}
                        loading={isLoading}
                    />
                  }
                  </> 


                }

                  </>:
                  <>
                   <Blockchain
                        key={account}
                        active={true}
                        chainData={chainData}
                        address={address}
                        chainId={chainId}
                        balances={balances}
                        actions={getBlockchainActions(chainId)}
                        show={false}
                        loading={isLoading}
                    />
                  </>
                );
            })   
            )              
            }
        </>

        );
    };

    return (
        <SLayout>
        <Column maxWidth={1000} spanHeight>
          {/* <Header session={session} ping={function (): Promise<void> {
                    throw new Error('Function not implemented.');
                } } disconnect={function (): Promise<void> {
                    throw new Error('Function not implemented.');
                } } /> */}
                {(!walletConnected) ? 
                  isInitializing ? "Loading..." : renderContent() :
          <SContent>{isInitializing ? "Loading..." : renderContent()}</SContent>}
        </Column>
          {renderModal()}
      </SLayout>
    );
};

export default WalletConnect;
