import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal, Table} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Header from "../components/Header.jsx";

const serverConfig = require("../config/server.js");

const ReviewOrder = () => {

  useEffect(() => {
    
  },[]);

  return (
    <div className="body-wrap bg-color8">
      <Header />
      <main>
        <Container fluid className='page-title title-center mb-4'>
          <h1>Review Your Order</h1>
        </Container>
        <Container fluid>
          <div className="content">
            <Table className="body-noborder bg-transparent">
              <tbody>
                <tr>
                  <td>Annual Fee<br/>Includes up to 5 verifiable credentials</td>
                  <td align="right"><b>$100</b></td>
                </tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
                <tr><td colSpan={2}></td></tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="border-0">Subtotal</td>
                  <td className="border-0" align="right"><b>$100</b></td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </Container>
        <Container fluid className='btn-container'>
          <Row>
            <Col>
              <Link to="/payment"><Button variant="primary">Proceed to Payment</Button></Link>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default ReviewOrder;
