import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faEdit, faUser, faUserSlash, faLock, faUnlock, faKey, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const serverConfig = require("../config/server.js");

const Users = ({handleLogout}) => {

  const mToken = sessionStorage.getItem("token");
  // const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      axios.post(serverConfig.api_base_url + "user_list_get", {token: mToken})
      .then((response) => {
        console.log('user_list_get response', response.data.items);
        if (response.data.status === 200) {
          let items = response.data.items;
          if (items !== null || items !== undefined){
            let appData = [];
            items.reverse().map((item, index) => {
              let curRole = [];
              curRole = item.roles;
              //console.log(curRole);
              //console.log(Array.of(curRole));
              return (<>

                {item.roles = [<div><ul className="ps-0 mb-0"> { curRole && curRole.map((itemRole, i) => <li key={i}>{ itemRole }</li>) }</ul><button className='btn-icon ms-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><FontAwesomeIcon icon={faEdit} /></button></div>]}

                {item.active = item.active === 0 ? (<><button className='btn-icon me-2' title='Enable user' onClick={() => handleActive(item.email_id, 1)}><FontAwesomeIcon icon={faUser} /></button> Disabled</>) : (<><button className='btn-icon me-2' title='Disable user' onClick={() => handleActive(item.email_id, 0)}><FontAwesomeIcon icon={faUserSlash} /></button> Enabled</>)}

                {item.locked = item.locked === 0 ? (<><button className='btn-icon me-2' title='Lock user' onClick={() => handleLocked(item.email_id, 1)}><FontAwesomeIcon icon={faLock} /></button> Unlocked</>) : (<><button className='btn-icon me-2' title='Unlock user' onClick={() => handleLocked(item.email_id, 0)}><FontAwesomeIcon icon={faUnlock} /></button> Locked</>)}

                {item.actions = (<div className="text-center"><button className='btn-icon me-4' onClick={() => handleChPWModal(item.name, item.email_id)} title="Password change"><FontAwesomeIcon icon={faKey} /></button></div>)}

                {appData.push(item)}
              </>);
            });
            console.log(appData);
            setUsers(appData);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

      /* role_list_get */
      axios.post(serverConfig.api_base_url + "role_list_get",{token: mToken})
      .then(response=>{
        console.log('role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, actions]);

  const data = {
    columns: [
      { label: "Name", field: "name", sort: true, key: "name" },
      { label: "Email Id", field: "email_id", sort: false, key: "email_id" },
      { label: "Active", field: "active", sort: false, key: "active" },
      { label: "Locked", field: "locked", sort: false, key: "locked" },
      { label: "Roles", field: "roles", sort: false, key: "roles" },
      { label: "Organization", field: "organization", sort: false, key: "roles" },
      { label: "Actions", field: "actions", sort: false, key: "roles" },
    ],
    rows: users,
  };

  const [rolesList, setRolesList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [rolesInputs, setRolesInputs] = useState([]);
  const [curUserRole, setCurUserRole] = useState([]);

  const [rolesModalShow, setRolesModalShow] = useState(false);
  const handleCloseRolesModal = () => {
    setRolesInputs([]); //clear roles checkbox on close of modal
    setCurUserRole([]);
    setCurrentUser(null);
    setCurrentUserEmail(null);
    setCurrentUserRole(null);
    setClicked(false);
    setRolesModalShow(false);
  };

  const handleRolesModal = (username, useremail, roles) => {
    console.log(username, useremail, roles);
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setCurrentUserRole(roles);
    setRolesModalShow(true);
  };

  const [clicked, setClicked] = useState(false);

  const handleCheckboxChange = (event) => {
    const roleId = event.target.id;
    //console.log('clicked', clicked);
    if (!clicked) {
      if (currentUserRole !== null && currentUserRole.length) {
        setClicked(true);
        setRolesInputs(currentUserRole);
      }
    }
    if (event.target.checked) {
      setRolesInputs((values) => [...values, roleId]);
    } else {
      if (clicked) {
        setRolesInputs(rolesInputs.filter((e) => e !== roleId));
      } else {
        setRolesInputs(
          currentUserRole && currentUserRole.filter((e) => e !== roleId)
        );
      }
    }
  };

  const handleApplyRole = () => {
    //console.log(roles);
    console.log("rolesInputs:", rolesInputs);
    console.log("CurrentUserRole:", currentUserRole);
    var grantList = [];
    var revokeList = [];
    // compare currentUserRole and rolesInputs
    for (var i = 0; i < rolesList.length; ++i) {
      let role = rolesList[i];
      if (
        rolesInputs.includes(role) &&
        (currentUserRole === null || !currentUserRole.includes(role))
      ) {
        grantList.push(role);
      } else if (
        currentUserRole != null &&
        currentUserRole.includes(role) &&
        !rolesInputs.includes(role)
      ) {
        revokeList.push(role);
      }
    }
    console.log("grant list: ", grantList);
    console.log("revoke list:", revokeList);
    var changed = false;

    if(grantList.length){
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: mToken, email_id: currentUserEmail, roles: grantList})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if (revokeList.length) {
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: mToken, email_id: currentUserEmail, roles: revokeList})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  };

  const handleExpirePwd = () => {
    console.log({token: mToken, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_expire_password",{token: mToken, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_expire_password response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [passwordSubmitDisabled, setPasswordSubmitDisabled] = useState(true);
  const [passwordChangeType, setPasswordChangeType] = useState(null);
  const [chPWMsg, setChPWMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChPWModal, setShowChPWModal] = useState(false);
  const closeChPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setChPWMsg("");
    setNewPassword("");
    setPasswordSubmitDisabled(true);
    setShowChPWModal(false);
    setIcon(<FontAwesomeIcon icon={faEye} title="Show password" />);
  }
  const handleChPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowChPWModal(true);
  }
  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'changePwdGenerate'){
          setNewPassword(response.data.password);
        } else if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            password:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
        setIcon(<FontAwesomeIcon icon={faEyeSlash} title="Hide password" />);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const [icon, setIcon] = useState(<FontAwesomeIcon icon={faEye} title="Show password" />);
  const togglePasswordVisibility = (event) => {
    var x = event.target.closest('.position-relative').querySelector('input');
    if (x.type === "password") {
      x.type = "text";
      setIcon(<FontAwesomeIcon icon={faEyeSlash} title="Hide password" />);
    } else {
      x.type = "password";
      setIcon(<FontAwesomeIcon icon={faEye} title="Show password" />);
    }
  }
  const handlePasswordChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    if(event.target.id === "new_password") {
      if(event.target.checked){
        document.getElementById("password").disabled = false;
        document.getElementById("password").required = true;
        document.getElementById("password").closest(".row").classList.add('d-flex');
        document.getElementById("password").closest(".row").classList.remove('d-none');
      } else {
        document.getElementById("password").disabled = true;
        document.getElementById("password").required = false;
        document.getElementById("password").closest(".row").classList.add('d-none');
        document.getElementById("password").closest(".row").classList.remove('d-flex');
        document.getElementById("password").value = "";
        setNewPassword("");
      }
    }

    var checkboxes = document.querySelectorAll('input[name="changePassword"]');
    var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    if(checkedOne){
      setPasswordSubmitDisabled(false);
    } else {
      setPasswordSubmitDisabled(true);
    }

    if(document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //only option 1 checked
      setPasswordChangeType("expire_password");
    } else if (!document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //only option 2 checked
      setPasswordChangeType("change_password_temp_0");
    } else if(document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //both option 1 and option 2 checked
      setPasswordChangeType("change_password_temp_1");
    } else if(!document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //none
      setPasswordChangeType(null);
    }

    if(name === 'password'){
      setNewPassword(value);
    }
  }
  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();
    console.log(newPassword);
    console.log(passwordChangeType);
    if(passwordChangeType === "expire_password"){
      handleExpirePwd();
    } else if (passwordChangeType === "change_password_temp_0"){
      handleChangePwd(0);
    } else if (passwordChangeType === "change_password_temp_1"){
      handleChangePwd(1);
    }
  }
  const handleChangePwd = (tempVal) => {
    console.log({token: mToken, email_id: currentUserEmail, password: newPassword, temporary: tempVal});
    axios.post(serverConfig.api_base_url + "user_password_change ",{token: mToken, email_id: currentUserEmail, password: newPassword, temporary: tempVal})
    .then(response=>{
      console.log('user_password_change  response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: mToken, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: mToken, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [inputs, setInputs] = useState({});
  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const handleCloseCreateUserModal = () => {
    setInputs({});
    setCreateUserModalShow(false);
  };
  const handleShowCreateUserModal = () => setCreateUserModalShow(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        setActions(!actions);// to refresh user list after user is created
        handleCloseCreateUserModal();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <div>
      <div className='page-heading'>
        <h1>Users</h1>
        {/* Create User */}
        <Button variant="transparent" className='btn-txt-black' onClick={handleShowCreateUserModal}>Create</Button>
        <Modal show={createUserModalShow} onHide={handleCloseCreateUserModal} backdrop="static"keyboard={false} centered className='modal-medium'>
          <Form onSubmit={handleCreateUser}>
            <Modal.Header closeButton>
              <Modal.Title>Create User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="email" placeholder="Email Id" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required />
              </Form.Group> */}
              <Row>
                <Col>
                  <div className='position-relative'>
                    <Form.Control type="password" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required className='pe-4' />
                    <span className='toggle-password' onClick={togglePasswordVisibility}>{icon}</span>
                  </div>
                </Col>
                <Col xs="auto"><Button id="createUserPwdGenerate" variant="transparent" onClick={handlePasswordGenerate} className='p-2'>Generate Password</Button></Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="transparent" onClick={handleCloseCreateUserModal}>Cancel</Button>
              <Button variant="primary" type="submit" className='ms-3'><FontAwesomeIcon icon={faUserPlus} className="me-2" />Create</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>

      {/* User list table */}
      <section>
        <div className="datatable datatable-users">
          <MDBDataTable
            striped
            borderless
            data={data}
            entries={10}
            entriesOptions={[10, 20, 30]}
            searchLabel="Type here"
          />
        </div>
      </section>

      {/* Edit user role */}
      <Modal
        show={rolesModalShow}
        onHide={handleCloseRolesModal}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-small"
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Edit roles for {currentUser}</p>
            <Row className="justify-content-center">
              {rolesList.map((item, index) => (
                <Col key={index} xs="auto">
                  <Form.Check
                    type="checkbox"
                    id={item}
                    value={item}
                    label={item}
                    defaultChecked={
                      currentUserRole && currentUserRole.includes(item)
                    }
                    onChange={handleCheckboxChange}
                  />
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseRolesModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleApplyRole}
              className="ms-3"
            >
              Apply
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Change Password */}
      <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered>
        <Form onSubmit={handlePasswordChangeSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Password change for {currentUser}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Check name="changePassword" type="checkbox" id="change_password" label="User must change password on next login" value="User must change password on next login" className='mb-3' onChange={handlePasswordChange} />
              <Form.Check name="changePassword" type="checkbox" id="new_password" label="Set a new password for the user" value="Set a new password for the user" className='mb-3' onChange={handlePasswordChange} />
              <Row className='d-none'>
                <Col>
                  <div className='position-relative'>
                    <Form.Control type="password" id="password" name="password" placeholder="New password" disabled onChange={handlePasswordChange} value={newPassword} className='pe-4' />
                    <span className='toggle-password' onClick={togglePasswordVisibility}>{icon}</span>
                  </div>
                </Col>
                <Col xs="auto"><Button id="changePwdGenerate" variant="transparent" onClick={handlePasswordGenerate} className='p-2'>Generate Password</Button></Col>
              </Row>
            </Form.Group>
            <p className="text-success">{chPWMsg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={closeChPWModal}>Cancel</Button>
            <Button variant="primary" type="submit" className='ms-3' disabled={passwordSubmitDisabled}>Submit</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;