import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import WalletConnect from "components/WalletConnect";
import { DEFAULT_CHIA_METHODS } from "../walletconnect/constants";
import { useWalletConnectClient } from '../walletconnect/contexts/ClientContext';
import { useJsonRpc } from '../walletconnect/contexts/JsonRpcContext';
import { useChainData } from "components/walletconnect/contexts/ChainDataContext";

const serverConfig = require("../../config/server");

const useOptions = () => {
  const fontSize = "14px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const Xch = () => {

  const { accounts, client, session } = useWalletConnectClient();
  const { chiaRpc, rpcResult } = useJsonRpc();
  const [signed, setSigned] = useState(false);
  const [address, setAddress] = useState('');
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const getUserAddress = () => {
    axios.post(serverConfig.api_base_url + "get_next_address",{"wallet_id":1, "new_address":false, "chain": "testnet"})
        .then(responseTargetAddress=>{
          console.log('target_address_response', responseTargetAddress?.data?.address);
          setAddress(responseTargetAddress?.data?.address);
        }).catch((err) => {
          console.log(err);
        });
  }

  useEffect(() => {
    console.log('accounts', accounts);
    //getUserAddress();
    if (signed) {
        const valid = (rpcResult as any).valid;

        if (valid) {
            
            const { data: { signature } } = JSON.parse((rpcResult as any).result);
        }
      }
}, [signed]);

  const [reissueFee, setReissueFee] = useState("3");
  const handleSelectReissueFee = (event : React.ChangeEvent<HTMLSelectElement>) => {
    setReissueFee(event.target.value)
  }

  const handleSubmit = async (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

  };

  return (
    <form onSubmit={handleSubmit} className="form-payment">
      <label>Price</label>
      <p>3 XCH</p>
      <Form.Group className="mb-4">
        <Form.Label>Fee</Form.Label>
        <Form.Select defaultValue={reissueFee} onChange={handleSelectReissueFee}>
          <option value="">Select</option>
          <option value="0.3">0.3 XCH (~3 mins)</option>
          <option value="1.0">1.0 0XCH (~10 mins)</option>
          <option value="1.5">1.5 XCH (~15 mins)</option>
        </Form.Select>
      </Form.Group>
      <label>Total Price</label>
      <p>{reissueFee !== '' && <>{reissueFee} XCH</>}</p>
      <div className="payment-btn-container">
       
         <WalletConnect isChiaUser={false} setPaymentCompleted={setPaymentCompleted} paymentCompleted={paymentCompleted} page="payments" proofsForVC="" addedVCProofs={''} setAddedVCProofs={''} connection={''} setConnection={''}/>
      </div>
    </form>
  );
};

export default Xch;
