import React from "react";
import AllRoutes from "./AllRoutes";
import { ChainDataContextProvider } from './components/walletconnect/contexts/ChainDataContext';
import { ClientContextProvider } from './components/walletconnect/contexts/ClientContext';
import { JsonRpcContextProvider } from './components/walletconnect/contexts/JsonRpcContext';

const App = () => {
    return (
        <ChainDataContextProvider>
        <ClientContextProvider>
          <JsonRpcContextProvider>
            <AllRoutes />
          </JsonRpcContextProvider>
        </ClientContextProvider>
      </ChainDataContextProvider>
    );
}

export default App;